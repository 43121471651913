import React from 'react';
import {CONSTANTS} from '../../Constants/Constants';

interface CheckboxRendererProps {
	value: boolean;
}

interface CheckboxRendererState {
	value: boolean;
}

export class CheckboxRenderer extends React.Component<CheckboxRendererProps, CheckboxRendererState> {
	constructor(props) {
		super(props);
		this.state = {
			value: props.value,
		};
	}

	render() {
		return this.state.value ? <span className="fa fa-check-circle" /> : CONSTANTS.EMPTY;
	}
}
