import dayjs from 'dayjs';
import {GetSensorTiltResult} from './DbModel/GetSensorTiltResult';

export class SensorTiltValue {
	constructor(init: GetSensorTiltResult) {
		this.Timestamp = dayjs(init.tstamp);
		this.TiltAngle = init.angle_degrees;
	}

	public Timestamp: dayjs.Dayjs;
	public TiltAngle: number;
}

export interface SensorTiltDeviation {
	timestamp: dayjs.Dayjs;
	tiltAngle: number;
}
