import React, {Component} from 'react';
import ContentWrapper from '../../Layout/ContentWrapper';
import KnowledgeHelp from '../../Shared/Components/KnowledgeBaseHelp';
import {Button, Container} from 'reactstrap';
import {StyledHeaderHelpIcon} from '../../Shared/Styles/StyledHeaderHelpIcon';
import {Access} from '../../Infrastructure/Authorization/Components';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';

//Ag Grid
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../../../styles/app/datagrid/styles.css';
import {ColumnDefinitions} from './ColumnDefinitions';
import {GridOptions} from './GridOptions';
//

//Constants
import {COLUMNS} from './Constants/Columns';
//

//Actions
import {executeIssueProfileCopyAction} from './ButtonsCell/Actions/IssueProfileCopying';
import {executeIssueProfileAddAction} from './ButtonsCell/Actions/IssueProfileAdding';
import {executeIssueProfileDisplayAction} from './ButtonsCell/Actions/IssueProfileDisplaying';
import {executeIssueProfileEditAction} from './ButtonsCell/Actions/IssueProfileEditing';
import {executeIssueProfileDeleteAction} from './ButtonsCell/Actions/IssueProfileDeleting';
//

import {MultiContext} from '../../Infrastructure/Authorization/Context/MultiContext';
import {ButtonsBindings} from './ButtonsCell/ButtonsBindings';
import {getIssueProfiles} from './Queries/IssueProfilesQueries';

class IssueProfilesListComponent extends Component {
	static contextType = MultiContext;

	constructor(props) {
		super(props);

		this.gridRef = React.createRef();
		this.savedFilterState = React.createRef();
		this.savedColumnState = React.createRef();
		this.girdApi = null;

		//Hamburger menu items
		this.copyIssueProfile = executeIssueProfileCopyAction;
		this.displayIssueProfile = executeIssueProfileDisplayAction;
		this.editIssueProfile = executeIssueProfileEditAction;
		this.deleteIssueProfile = executeIssueProfileDeleteAction;
		//

		this.state = {
			issueProfiles: [], //This array will be used in the future for events. This array a kind of state of issue profiles.
			rowData: [],
			columnDefs: ColumnDefinitions,
			agGridOptions: GridOptions,
		};

		ButtonsBindings(this); //The callback functions of hamburger menu options are bound, to be able to use properties of this.
	}

	addIssueProfile = e => {
		e.preventDefault();
		executeIssueProfileAddAction(this);
	};

	onGridReady = params => {
		getIssueProfiles(this);
		setTimeout(() => {
			params.api.sizeColumnsToFit();
		}, 50);
	};

	redrawAllRows = () => {
		this.gridRef.current.api.redrawRows();
	};

	deselectAll = e => {
		this.gridRef.current.api.deselectAll();
	};

	cellClickedListener = event => {
		//console.log('cellClicked', event); only for dev experience.
	};

	cellDoubleClickedListener = event => {
		if (event.colDef && event.data != null && event.colDef.field && event.colDef.field !== COLUMNS.MENU) {
			if (event.data.Locked) {
				executeIssueProfileDisplayAction(this, event.data.id);
			} else {
				executeIssueProfileEditAction(this, event.data);
			}
		}
	};

	setColumnDefs = () => {
		const columnDefs = this.state.columnDefs;
		columnDefs.forEach(function (colDef, index) {
			colDef.headerName = 'C' + index;
		});
		this.gridRef.current.api.setColumnDefs(columnDefs);
	};

	getRowId = params => {
		return params.data.id;
	};

	render() {
		return (
			<ContentWrapper>
				<div className="content-heading">
					<div>Issue Profiles</div>
					<StyledHeaderHelpIcon>
						<KnowledgeHelp id="041" />
					</StyledHeaderHelpIcon>
					<div className="ml-auto">
						<Access
							access={accessPermissions.settings.child.issueProfiles.child.limitProfile.child.addIssueProfile}
							roles={userRoles.default}
						>
							<Button id="btnAddIssueProfile" color="primary" size="lg" onClick={this.addIssueProfile}>
								<span>Add Issue Profile</span>
							</Button>
						</Access>
					</div>
				</div>
				<Container fluid>
					<div style={this.state.agGridOptions.containerStyle}>
						<div style={this.state.agGridOptions.gridStyle} className={this.state.agGridOptions.theme}>
							<AgGridReact
								ref={this.gridRef}
								getRowId={this.getRowId}
								noRowsOverlayComponent={this.state.agGridOptions.noRowsOverlayComponent}
								loadingOverlayComponent={this.state.agGridOptions.loadingOverlayComponent}
								statusBar={this.state.agGridOptions.statusBar}
								rowGroupPanelShow={this.state.agGridOptions.rowGroupPanelShow}
								rowSelection={this.state.agGridOptions.rowSelection}
								sideBar={this.state.agGridOptions.sideBar}
								enableCellChangeFlash={this.state.agGridOptions.enableCellChangeFlash}
								defaultColDef={this.state.agGridOptions.colDefinationSettings}
								enableRangeSelection={this.state.agGridOptions.enableRangeSelection}
								rowData={this.state.rowData}
								rowClassRules={this.state.agGridOptions.rowClassRules}
								columnDefs={this.state.columnDefs}
								onCellClicked={this.cellClickedListener}
								animateRows={this.state.agGridOptions.animateRows}
								onGridReady={this.onGridReady}
								columnHoverHighlight={this.state.agGridOptions.columnHoverHighlight}
								autoGroupColumnDef={this.state.agGridOptions.autoGroupColumnDef}
								showOpenedGroup={this.state.agGridOptions.showOpenedGroup}
								groupDisplayType={this.state.agGridOptions.groupDisplayType}
								groupHideOpenParents={this.state.agGridOptions.groupHideOpenParents}
								suppressContextMenu={this.state.agGridOptions.suppressContextMenu}
								onCellDoubleClicked={this.cellDoubleClickedListener}
								suppressRowTransform
							/>
						</div>
					</div>
				</Container>
			</ContentWrapper>
		);
	}
}

export default IssueProfilesListComponent;
