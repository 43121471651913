export interface TiltWarningPosition {
	id: number;
	name: string;
	reference_x: number;
	reference_y: number;
	reference_z: number;
}

export const TILT_WARNING_INITIAL_POSITIONS: {[key: number]: TiltWarningPosition} = {
	0: {id: 0, name: 'Horizontal', reference_x: 0.0, reference_y: 0.0, reference_z: 1.0},
	1: {id: 1, name: 'Vertical', reference_x: 0.0, reference_y: 1.0, reference_z: 0.0},
};
