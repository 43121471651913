export const RUN_EVENTS = {
	START_RUN: ['Start run', 'start_run'],
	STOP_RUN: ['Stop run', 'stop_run'],
};

export const ALARM_EVENTS = {
	ALARMING_ON: ['Alarming on', 'alarming_on', 1],
	ALARMING_OFF: ['Alarming off', 'alarming_off', 0],
};

export const FLIGHT_MODE_EVENTS = {
	FLIGHT_MODE_ON: ['Flight mode on', 'flight_mode_on', 1],
	FLIGHT_MODE_OFF: ['Flight mode off', 'flight_mode_off', 0],
};

export const SENSOR_LIMIT_ALARMS = {
	UPPER_LIMIT_ALARM: ['Upper limit alarm', 'upper_limit_alarm'],
	LOWER_LIMIT_ALARM: ['Lower limit alarm', 'lower_limit_alarm'],
};

export const SENSOR_ISSUE_ALARMS = {
	RADIO_CONNECTION_WARNING: ['Radio connection warning', 'radio_connection_warning'],
	LOST_MEASUREMENT_ALARM: ['Lost measurement alarm', 'lost_measurement_alarm'],
	LOW_BATTERY_WARNING: ['Low battery warning', 'low_battery_warning'],
	MISSING_COMMUNICATION_WARNING: ['Missing communication warning', 'missing_communication_warning'],
	MISSING_VALUE_ALARM: ['Missing value alarm', 'missing_value_alarm'],
	SENSOR_FAILURE_ALARM: ['Sensor failure alarm', 'sensor_failure_alarm'],
	TILT_WARNING: ['Tilt warning', 'tilt_warning'],
};

export const LOGGER_STATUS_EVENTS = {
	UNDEFINED: 'Undefined',
	INIT: 'Init',
	SHELF: 'Shelf',
	PAIRING: 'Pairing',
	START: ['Start', 'Start'],
	LOG_DELAY: ['Delayed', 'LogDelayed'],
	LOG_TRANSIT: ['Transit', 'LogTransit'],
	LOG_PAUSED: ['Paused', 'LogPaused'],
	LOG_ARRIVED: ['Arrived', 'LogArrived'],
	LOG_STOPPED: ['Stopped', 'StopStopped'],
	CALIB: ['Calibration', 'Calibration'],
	READ_OUT: 'Read out',
	FATAL_ERROR: 'Fatal error',
	REQUIRED_STATUSES: ['Start', 'LogDelayed', 'LogTransit', 'LogPaused', 'LogArrived', 'StopStopped', 'Calibration'],
};

export const MENU_SET_MARK_EVENTS = {
	MARK_COUNTER: ['Mark counter', 'mark_counter'],
};

export const REPLACE_SENSOR_EVENTS = {
	REPLACE_SENSOR: ['Replace Sensor', 'replace_sensor'],
};

export const OCCURRENCES = {
	REQUIRED_TYPES: ['LoggerStatusChange', 'SensorCommunication'],
};

export const OTHER_OCCURRENCES = {
	REQUIRED_TYPES: ['FlightDetectorEvent', 'MenuAlarmingOnOff', 'MenuSetMark'],
};

export const PLOT_LINE_COLORS = {
	DEFAULT: 'purple',
	RELOADED_VALUES: '#dae6dc',
	ALARMING_INACTIVE: 'rgba(240,240,240, 0.7)',
	COMMUNICATION_TIMES: 'rgb(204,214,235)', //same grey as x axis line
	START_STOP_RUN: 'green',
	ALARMING_ON_OFF: 'red',
	LOGGERS_STATUSES: 'orange',
	SENSOR_LIMIT_ALARMS: 'blue',
	SENSOR_ISSUE_ALARMS: 'brown',
	FLIGHT_MODE_ON_OFF: 'cyan',
	MENU_SET_MARK: 'darkcyan',
	REPLACE_SENSOR: 'lightblue',
	NO_CONNECTION: 'rgba(250, 178, 70, 0.3)',
	SHORT_CIRCUIT: 'rgba(252, 106, 38, 0.3)',
	SENSOR_ERROR: 'rgba(255, 79, 43, 0.3)',
	CALIBRATION: 'rgba(245, 219, 71, 0.3)',
	H4LIMIT: '#ff9d9d',
	H3LIMIT: '#ffb2b2',
	H2LIMIT: '#ffd3d3',
	H1LIMIT: '#ffeded',
	L1LIMIT: '#ededff',
	L2LIMIT: '#d8d8ff',
	L3LIMIT: '#c5c5ff',
	WARNING_THRESHOLD: '#ffb2b2',
};
