import React from 'react';
import {RecipientSelectRows} from './RecipientSelectRows';
import {IssueAlarmFormState} from '../../../SensorIssueForm/IssueAlarmFormState';

type IssueAlarmsRecipientsProps = {
	sensorIssuesForm: IssueAlarmFormState;
	show: boolean;
};

export default function IssueAlarmsRecipientsComponent(props: IssueAlarmsRecipientsProps) {
	return props.show && <RecipientSelectRows recipientSelectState={props.sensorIssuesForm.recipients} />;
}
