import {Select} from 'antd';
import React from 'react';

interface TiltWarningThresholdAngleSelectProps {
	value: number;
	disabled: boolean;
}

export default function TiltWarningThresholdAngleSelectComponent(props: Readonly<TiltWarningThresholdAngleSelectProps>) {
	return (
		<Select id="selectTiltWarningThresholdAngle" style={{width: '100%'}} value={props.value} disabled={props.disabled}>
			{Array.from({length: 12}, (_, i) => (i + 1) * 15).map(angle => (
				<option key={angle} value={angle}>
					{angle}°
				</option>
			))}
		</Select>
	);
}
