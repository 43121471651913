import IssueProfileService from '../../../../Services/IssueProfiles/IssueProfilesService';
import RequestLogger from '../../../../Infrastructure/Requests/Logger/RequestLogger';

export async function getIssueProfile(issueProfileId) {
	const response = await IssueProfileService.getIssueProfile(
		issueProfileId,
		RequestLogger.createLogData('issue-profiles', 'load-issue-profile', 'onLoad')
	);
	return response.data;
}
