import {MODES} from './Modes';

export const configurationIssueProfileFormSettings = mode => {
	switch (mode) {
		case MODES.DISPLAY:
			return IssueProfilesDisplaySettings;
		case MODES.ADD:
			return IssueProfilesAddSettings;
		case MODES.EDIT:
			return IssueProfilesEditSettings;
		default:
			return null;
	}
};

const IssueProfilesDisplaySettings = {
	profileNameInputDisabled: true,
	lostMeasurementAlarmCheckboxDisabled: true,
	missingCommunicationWarningDisabled: true,
	sensorFailureAlarmInputDisabled: true,
	radioConnectionWarningCheckboxDisabled: true,
	lowBatteryWarningCheckboxDisabled: true,
	lightWarningCheckboxDisabled: true, //this feature will be added in the future
	tiltWarningCheckboxDisabled: true, //this feature will be added in the future
};

const IssueProfilesAddSettings = {
	profileNameInputDisabled: false,
	lostMeasurementAlarmCheckboxDisabled: false,
	missingCommunicationWarningDisabled: false,
	sensorFailureAlarmInputDisabled: false,
	radioConnectionWarningCheckboxDisabled: false,
	lowBatteryWarningCheckboxDisabled: false,
	lightWarningCheckboxDisabled: false, //this feature will be added in the future
	tiltWarningCheckboxDisabled: false, //this feature will be added in the future
};

const IssueProfilesEditSettings = {
	profileNameInputDisabled: false,
	lostMeasurementAlarmCheckboxDisabled: false,
	missingCommunicationWarningDisabled: false,
	sensorFailureAlarmInputDisabled: false,
	radioConnectionWarningCheckboxDisabled: false,
	lowBatteryWarningCheckboxDisabled: false,
	lightWarningCheckboxDisabled: false, //this feature will be added in the future
	tiltWarningCheckboxDisabled: false, //this feature will be added in the future
};
