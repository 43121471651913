import React, {ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {AuthContext} from '../../common/context';
import {PredictionService} from '../../common/services';
import {IPrediction} from '@elproag/predict';
import {AgGridReact} from 'ag-grid-react';
import {ColDef, GridOptions} from 'ag-grid-enterprise';
import {AgGridWrapper} from '../Shared/Components';
import {formatDayJsInUserTz} from '../Infrastructure/DateTime/DateTimeUtils';
import CountStatusBar from '../SensorListView/CountStatusBar';
import dayjs from 'dayjs';
import {Link} from 'react-router-dom';
import {Paths} from '../../common/constants';
import {Card} from '../Common';
import {ErrorAlert} from '../Common/Alerts';
import {Button} from 'antd';

function SensorAnalysisCellRenderer(params: Readonly<{value: number}>): ReactNode {
	return <Link to={`${Paths.Sensor.Analysis}/${params.value}`}>{params.value.toString()}</Link>;
}

function ExportCsvButton(props: Readonly<{exportFunction: () => void}>): ReactNode {
	return (
		<div className={'ag-status-name-value'}>
			<Button onClick={props.exportFunction}>Export CSV</Button>
		</div>
	);
}

export function PredictionGrid() {
	const gridRef = useRef<AgGridReact>(null);
	const [predictions, setPredictions] = useState<IPrediction[]>([]);
	const authContext = useContext(AuthContext);

	const orgUuid = authContext.Organization?.Uuid;

	useEffect(() => {
		if (orgUuid) {
			PredictionService.GetPredictions(orgUuid)
				.then(setPredictions)
				.catch(_ => ErrorAlert.fire({text: 'Failed to load predictions'}));
		}
	}, [orgUuid]);

	const colDefs: ColDef<IPrediction>[] = useMemo(
		() => [
			{
				field: 'sensorRunId',
				headerName: 'Sensor ID',
				cellRenderer: SensorAnalysisCellRenderer,
			},
			{
				field: 'timestampUnixUtc',
				valueGetter: params => dayjs.unix(params.data.timestampUnixUtc),
				valueFormatter: params => formatDayJsInUserTz(params.value),
				headerName: 'Timestamp',
				filter: 'agDateColumnFilter',
				filterParams: {
					buttons: ['reset'],
				},
			},
		],
		[]
	);

	const exportCsv = useCallback(() => {
		gridRef.current?.api.exportDataAsCsv();
	}, [gridRef.current]);

	const statusBar: GridOptions['statusBar'] = useMemo(
		() => ({
			statusPanels: [
				{statusPanel: CountStatusBar, align: 'left'},
				{statusPanel: ExportCsvButton, statusPanelParams: {exportFunction: exportCsv}, align: 'right'},
			],
		}),
		[exportCsv]
	);

	return (
		<Card title="Predictions" styles={{body: {height: '95%'}}} style={{height: '100%'}}>
			<AgGridWrapper>
				<AgGridReact<IPrediction> rowData={predictions} columnDefs={colDefs} statusBar={statusBar} ref={gridRef}></AgGridReact>
			</AgGridWrapper>
		</Card>
	);
}
