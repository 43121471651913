import React, {useEffect, useState} from 'react';
import {Form, Select} from 'antd';
import {IssueProfile} from './IssueAlarmFormState';
import IssueProfileService from '../../../../Services/IssueProfiles/IssueProfilesService';
const {Option} = Select;

type IssueProfileSelectProps = {
	initialIssueProfile: IssueProfile;
	onSelectCallback: (issueProfile: IssueProfile) => void;
	disabled: boolean;
	onComponentReady?: () => void;
};

export default function IssueProfileSelectComponent(props: IssueProfileSelectProps) {
	const [issueProfiles, setIssueProfiles] = useState<IssueProfile[]>([]);
	const [issueProfile, setIssueProfile] = useState<IssueProfile>(props.initialIssueProfile);

	useEffect(() => {
		IssueProfileService.issueProfiles().then(function (response) {
			setIssueProfiles(response.data.sort((i1, i2) => i1.name.localeCompare(i2.name)));
			props.onComponentReady && props.onComponentReady();
		});
	}, []);

	useEffect(() => {
		if (!issueProfiles || issueProfiles.length == 0 || !issueProfile) {
			return;
		}

		onChange(issueProfile.id);
	}, [issueProfiles]);

	function onChange(value: number) {
		const selectedIssueProfile = issueProfiles.find(l => l.id == value);
		setIssueProfile(selectedIssueProfile);
		props.onSelectCallback(selectedIssueProfile);
	}

	return (
		<Form.Item label="Issue profile" name="Issue profile" initialValue={issueProfile?.id} rules={[{required: !props.disabled}]}>
			<Select
				id="selectIssueAlarmCreationType"
				style={{width: '100%'}}
				value={issueProfile?.id || undefined}
				disabled={props.disabled}
				onChange={onChange}
			>
				{issueProfiles
					.sort((i1, i2) => i1.name.localeCompare(i2.name))
					.map(issueProfile => {
						return (
							<Option id={issueProfile.name} key={issueProfile.id} value={issueProfile.id}>
								{issueProfile.name}
							</Option>
						);
					})}
			</Select>
		</Form.Item>
	);
}
