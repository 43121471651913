// CORE
import React, {useContext, useEffect, useState} from 'react';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';

// LAYOUT CORE
import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';

// SERVICES
import Auth from './components/Infrastructure/Authentication/Authentication';
import Shared from './components/Shared/Shared';

// DEV
import Development from './components/Development/Development';
import ReactDevelopment from './components/Development/ReactDev/DevelopmentReactPatterns';

// DASHBOARD
import Dashboard from './components/Dashboard/Dashboard';
import {SensorListView} from './components';
import SensorAnalysis from './components/Analysis/SensorAnalysis';

// SENSOR
import AddSensor from './components/Dashboard/Sensor/AddSensor/AddSensor';
import EditLiberoGx from './components/Dashboard/Sensor/EditSensor/EditLiberoGx';
import EditEcologProGx from './components/Dashboard/Sensor/EditSensor/EditEcologProGx';
import EditSensor from './components/Dashboard/Sensor/EditSensor/EditSensor';
import SensorShowSummary from './components/Dashboard/Sensor/Forms/SensorSummary/SensorShowSummary';
import ReplaceSensor from './components/Dashboard/Sensor/ReplaceSensor/ReplaceSensor';
import ConnectModule from './components/Dashboard/Sensor/ConnectModule/ConnectModule';

// DEVIATIONS
import Deviations from './components/Deviations/Deviations';
import DeviationDetail from './components/Deviations/Views/DeviationDetail';
import DeviationAcknowledge from './components/Deviations/Views/DeviationAcknowledge';

// AUDIT TRAIL
import AuditTrail from './components/AuditTrail/AuditTrail';

// CALIBRATION
import CalibrationsGrid from './components/Calibration/CalibrationsGrid';
import CalibrationAnalysis from './components/Calibration/CalibrationAnalysis';

// SETTINGS
import SensorGroups from './components/Settings/SensorGroups/SensorGroups';
import SensorGroup from './components/Settings/SensorGroups/SensorGroup';
import {LimitProfileDetail, LimitProfilesGrid} from './components/LimitProfile';
import IssueProfilesListComponent from './components/Settings/IssueProfiles/IssueProfilesListComponent';
import IssueProfileComponent from './components/Settings/IssueProfiles/IssueProfile/Components/IssueProfileComponent';
import {ExtendLicenses, ExtendRetiredLicenses, Licenses, RetireLicenses} from './components/Settings/Licenses/Licenses';
import Billing from './components/Settings/Billing/Billing';
import AddCreditCard from './components/Settings/Billing/AddCreditCard/AddCreditCard';
import RechargePrepaid from './components/Settings/Billing/RechargePrepaidCard/RechargePrepaid';
import {OrganizationForm} from './components/Settings';
import UserProfile from './components/Settings/UserProfile/UserProfile';
import UserManagement from './components/Settings/UserManagement/UserManagement';

// CONTEXT
import {AccessContext} from './components/Infrastructure/Authorization/Context/AccessContext';
import {userRoles} from './components/Infrastructure/Authorization/Access';
import {authUtils} from './components/Infrastructure/Authorization/Utils/index';

// PUBLIC
import Login from './components/Login/Login';
import ForgotPassword from './components/Registration/ForgotPassword';
import Register from './components/Registration/Register';
import Confirmation from './components/Registration/Confirmation';
import MailConfirmation from './components/Registration/MailConfirmation';
import ResetPassword from './components/Registration/ResetPassword';
import Resend from './components/Registration/Resend';
import BridgeConfiguration from './components/Bridge/BridgeConfiguration';
import ConfirmationChangePassword from './components/Registration/ConfirmationChangePassword';
import DeviceStatus from './components/PublicViews/DeviceStatus';
import ExternalRedirect from './components/DeviceStatus/ExternalRedirect';
import LiveLink from './components/PublicViews/LiveLink';
import {TestComponent} from './components/Development';
import {CompatRoute} from 'react-router-dom-v5-compat';
import {OpenSourceLicensesView} from './components/Views';
import {AuthContext} from './common/context';
import {Paths} from './common/constants';
import {GetDeviceViewSettings} from './common/services/WebserverServicePro/UserSettingsService';
import {PredictiveOverview} from './components/Predictive/PredictiveOverview';

const listOfPages = [
	'/confirmationChangePassword',
	'/login',
	'/forgotPassword',
	'/register',
	'/resend',
	'/bridge',
	'/deviceStatus',
	'/liveLink',
];

const RenderPrivateRoute = ({component: Component, routeParams}) => {
	const authContext = useContext(AuthContext);
	// Legacy compatibility for Class Components
	const history = useHistory();
	const location = useLocation();
	return (
		<Route
			{...routeParams}
			render={props =>
				Auth.isAuthenticated() ? (
					authContext && <Component {...props} history={history} location={location} />
				) : (
					<Redirect to={{pathname: '/login', state: {from: props.location}}} />
				)
			}
		/>
	);
};

const checkRouteRoleAccess = (allowedRoles, user, path) => {
	if (!user) return null;

	// check if user can see current navigation item, based on his roles
	if (!allowedRoles.some(role => user.access_settings.settings.roles.includes(role))) {
		return <Redirect to={{pathname: '/login'}} />;
	}

	// role is ok... is access for this user ok?
	const access = authUtils.findAccessElement(path, user.access_settings.access);

	if (access && !access.allow) {
		return <Redirect to={{pathname: '/login'}} />;
	}

	return null;
};

const RenderRoute = ({component: Component, allowedRoles, user, routeParams}) => {
	const parentRoute = routeParams.parent.split('/').filter(p => p !== '');
	const pagePath = routeParams.computedMatch.path
		.split('/')
		.filter(char => char.charAt(0) !== ':')
		.slice(-1)[0];

	const path = pagePath === parentRoute[0] ? [pagePath] : [...parentRoute, pagePath];

	if (routeParams.checkAccessParamKeys) {
		path.push(routeParams.computedMatch.params[routeParams.checkAccessParamKeys[0]]);
	}

	let renderValue = checkRouteRoleAccess(allowedRoles, user, path);

	// if not null = render route
	if (!renderValue) {
		renderValue = RenderPrivateRoute({component: Component, routeParams});
	}

	// returns login route not allowed
	return renderValue;
};

function PrivateRoute({component: Component, allowedRoles, ...rest}) {
	//const {user, accessToken} = useContext(AccessContext);
	//const decodedToken = authUtils.decodeToken(accessToken);
	//const isEqual = JSON.stringify(user) === JSON.stringify(decodedToken);
	const {user} = useContext(AccessContext);
	return RenderRoute({component: Component, allowedRoles, user, routeParams: rest});
}

const Routes = () => {
	const history = useHistory();
	const location = useLocation();
	const currentKey = location.pathname.split('/')[1] || '/';
	const externalRedirectUrl = currentKey.split('=');

	const [initialPage, setInitialPage] = useState('/login');

	const isPublicRoute =
		listOfPages.indexOf(location.pathname) > -1 ||
		location.pathname.indexOf('confirmEmail') > -1 ||
		location.pathname.indexOf('confirmation') > -1 ||
		location.pathname.indexOf('resetPassword') > -1 ||
		location.pathname.indexOf('deviceStatus') > -1 ||
		location.pathname.indexOf('liveLink') > -1 ||
		(externalRedirectUrl.length >= 2 && externalRedirectUrl[0] === 'ID') ||
		window.location.href === process.env.DEVICE_CHECK_REDIRECT_URL;

	const timeout = {enter: 500, exit: 500};
	const animationName = 'rag-fadeIn';

	useEffect(() => {
		if (Auth.isAuthenticated) {
			GetDeviceViewSettings()
				.then(response => {
					setInitialPage(response.view);
				})
				.catch(() => setInitialPage('/dashboard'));
		}

		if (Shared.isDevEnv()) {
			return;
		}
		const script = document.createElement('script');
		script.src = '//js.hs-scripts.com/5859635.js';
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	if (isPublicRoute) {
		return (
			<BasePage>
				<Switch location={location}>
					<CompatRoute path="/login" component={Login} />
					<CompatRoute path="/forgotPassword" component={ForgotPassword} />
					<CompatRoute path="/register" component={Register} />
					<CompatRoute path="/resend" component={Resend} />
					<CompatRoute path="/confirmation/:token" component={Confirmation} />
					<CompatRoute path="/confirmEmail/:token" component={MailConfirmation} />
					<CompatRoute path="/resetPassword/:token" component={ResetPassword} />
					<CompatRoute path="/confirmationChangePassword/:token" component={ConfirmationChangePassword} />
					<CompatRoute path="/bridge" component={BridgeConfiguration} />
					<CompatRoute path="/deviceStatus/:serialNumber?" component={DeviceStatus} />
					<CompatRoute path="/liveLink/:key?" component={LiveLink} />
					<CompatRoute path="/(serial)?" component={ExternalRedirect} />
				</Switch>
			</BasePage>
		);
	} else {
		return (
			<Base>
				<TransitionGroup>
					<CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
						<Switch location={location}>
							{/*  ========================= DASHBOARD & DATAGRID ========================= */}
							<PrivateRoute
								path="/dashboard"
								parent="/devicesview"
								component={Dashboard}
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/datagrid"
								parent="/devicesview"
								component={SensorListView}
								allowedRoles={[...userRoles.default]}
							/>
							{/* ADD,EDIT,CONNECT SENSOR */}
							<PrivateRoute
								path="/addSensor"
								parent="/devicesview/dashboard"
								component={AddSensor}
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/editSensor/:id"
								component={EditSensor}
								parent="/devicesview/dashboard/sensor"
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/sensorShowSummary/:id"
								component={SensorShowSummary}
								parent="/devicesview/dashboard/sensor"
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/startNewRun/:id"
								component={EditLiberoGx}
								parent="/devicesview/dashboard/sensor"
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/editEcologProGx/:id"
								component={EditEcologProGx}
								parent="/devicesview/dashboard/sensor"
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/replaceSensor/:id"
								parent="/devicesview/dashboard/sensor"
								component={ReplaceSensor}
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/connectModule/:id/:serialNumber/:prefix"
								parent="/devicesview/dashboard/sensor"
								component={ConnectModule}
								allowedRoles={[...userRoles.default]}
							/>
							{/* SENSOR ANALYSIS */}
							<PrivateRoute
								path="/sensorAnalysis/:id"
								parent="/devicesview/dashboard"
								component={SensorAnalysis}
								allowedRoles={[...userRoles.default]}
							/>
							{/* ============================================================= */}

							{/* ========================= DEVIATIONS ======================== */}
							<PrivateRoute
								path="/deviations"
								parent="/deviations"
								component={Deviations}
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/deviationsDetail/:id/:sensorId?"
								parent="/deviations"
								component={DeviationDetail}
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/deviationAcknowledge/:id"
								parent="/deviations/deviationsDetail"
								component={DeviationAcknowledge}
								allowedRoles={[...userRoles.default]}
							/>
							{/* ============================================================= */}

							{/* ========================= AUDIT TRAIL ======================= */}
							<PrivateRoute
								path="/auditTrail"
								parent="/auditTrail"
								component={AuditTrail}
								allowedRoles={[...userRoles.default]}
							/>
							{/* ============================================================= */}

							{/* ========================= Calibration ======================= */}
							<PrivateRoute
								exact
								path="/calibrations"
								parent="/calibrations"
								component={CalibrationsGrid}
								allowedRoles={[...userRoles.calibrator]}
							/>
							<PrivateRoute
								path="/calibrations/:id"
								parent="/calibrations"
								component={CalibrationAnalysis}
								allowedRoles={[...userRoles.calibrator]}
							/>
							{/* ============================================================= */}

							{/* =================== SETTINGS - SENSOR GROUPS ================ */}
							<PrivateRoute
								path="/sensorGroups"
								parent="/settings"
								component={SensorGroups}
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/sensorGroup/:action"
								parent="/settings/sensorGroups"
								checkAccessParamKeys={['action']}
								component={SensorGroup}
								allowedRoles={[...userRoles.default]}
							/>
							{/* ============================================================= */}

							{/* =================== SETTINGS - LIMIT PROFILE =================*/}
							<PrivateRoute
								path="/limitProfiles/new"
								parent="/settings/limitProfiles"
								component={LimitProfileDetail}
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/limitProfiles/:profileId"
								parent="/settings/limitProfiles"
								component={LimitProfileDetail}
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/limitProfiles"
								parent="/settings"
								component={LimitProfilesGrid}
								allowedRoles={[...userRoles.default]}
							/>

							{/* =================== SETTINGS - ISSUE PROFILE ================ */}
							<PrivateRoute
								path="/issueProfiles"
								parent="/settings"
								component={IssueProfilesListComponent}
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/issueProfile/:action"
								parent="/settings/issueProfiles"
								checkAccessParamKeys={['action']}
								component={IssueProfileComponent}
								allowedRoles={[...userRoles.default]}
							/>
							{/* ============================================================= */}

							{/* =================== SETTINGS - LICENCES ===================== */}
							<PrivateRoute path="/licenses" parent="/settings" component={Licenses} allowedRoles={[...userRoles.default]} />
							<PrivateRoute
								path="/extendLicenses"
								parent="/settings/licenses"
								component={ExtendLicenses}
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/extendRetiredLicenses"
								parent="/settings/licenses"
								component={ExtendRetiredLicenses}
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/retireLicenses"
								parent="/settings/licenses"
								component={RetireLicenses}
								allowedRoles={[...userRoles.default]}
							/>
							{/* ============================================================= */}

							{/* =================== SETTINGS - BILLING ====================== */}
							<PrivateRoute path="/billing" parent="/settings" component={Billing} allowedRoles={[...userRoles.default]} />
							<PrivateRoute
								path="/addCreditCard"
								parent="/settings/billing/creditCard"
								component={AddCreditCard}
								allowedRoles={[...userRoles.default]}
							/>
							<PrivateRoute
								path="/rechargePrepaid"
								parent="/settings/billing/prepaidCard"
								component={RechargePrepaid}
								allowedRoles={[...userRoles.default]}
							/>
							{/* ============================================================= */}

							{/* =================== SETTINGS - ORGANIZATION ================= */}
							<PrivateRoute
								path="/organization"
								parent="/settings"
								component={OrganizationForm}
								allowedRoles={[...userRoles.default]}
							/>
							{/* ============================================================= */}

							{/* =================== SETTINGS - USER PROFILE ================= */}
							<PrivateRoute
								path="/userProfile"
								parent="/settings"
								component={UserProfile}
								allowedRoles={[...userRoles.default]}
							/>
							{/* ============================================================= */}

							{/* =================== SETTINGS - USER MANAGEMENT ============== */}
							<PrivateRoute
								path="/userManagement"
								parent="/settings"
								component={UserManagement}
								allowedRoles={[...userRoles.admin]}
							/>
							{/* ============================================================= */}

							{/* =================== SETTINGS - USER MANAGEMENT ============== */}
							<PrivateRoute
								path={Paths.Settings.PredictiveAnalytics}
								parent="/settings"
								component={PredictiveOverview}
								allowedRoles={[...userRoles.admin]}
							/>
							{/* ============================================================= */}

							{/* ============================================================= */}
							<PrivateRoute
								path="/openSourceLicenses"
								parent="/"
								component={OpenSourceLicensesView}
								allowedRoles={[...userRoles.default]}
							/>
							{/* ============================================================= */}

							{/* =================== DEV ===================================== */}
							{Shared.isDevEnv() && (
								<PrivateRoute
									path="/reactdev"
									parent="/settings"
									component={ReactDevelopment}
									allowedRoles={[...userRoles.admin]}
								/>
							)}
							{Shared.isDevEnv() && (
								<PrivateRoute
									path="/development"
									parent="/settings"
									component={Development}
									allowedRoles={[...userRoles.admin]}
								/>
							)}
							{Shared.isDevEnv() && (
								<PrivateRoute
									path="/testComponent"
									parent="/settings"
									component={TestComponent}
									allowedRoles={[...userRoles.admin]}
								/>
							)}
							{/* ============================================================= */}
							<Redirect to={initialPage} />
						</Switch>
					</CSSTransition>
				</TransitionGroup>
			</Base>
		);
	}
};

export default Routes;
