import React, {Component} from 'react';
import {ContentWrapper} from '../../../Layout';
import {Trans} from 'react-i18next';
import NewModuleCard from '../../../Common/NewModuleCard';
import RegistryHelper, {showLiberoGWarning, showPriceWarning} from '../Utils/RegistryHelper';
import {GetSensorSteps} from './GetSensorSteps';
import RadialSteps from '../../../Common/RadialSteps';
import pubsub from 'pubsub-js';
import {MODULE_FAMILIES} from '../../../Shared/Constants/Module';
import Message from '../../../Shared/Components/Message';
import SerialNumberInfo from '../../../Shared/SerialNumberInfo';
import FormBuilder from '../../../Forms/FormBuilder';
import {RequestLogger} from '../../../Infrastructure/Requests/Logger';
import {MultiContext} from '../../../Infrastructure/Authorization/Context/MultiContext';
import {
	getSensorBasicsFormSettingsForConfiguration,
	getSensorPaymentMethodSettingsForConfiguration,
	getSensorSummarySettingsForConfiguration,
} from '../Helpers/WizardSettingsBuilder';
import findFamilyTypeFromTemplateName from '../../../Shared/Extensions/DeviceModuleFamilyTypeFinder';
import {AddSensorProps} from './AddSensorProps';
import {AddSensorState} from './WizardState';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import {CurrentPriceExtension, CurrentPriceProps, CurrentPriceResult} from '../../../../common/util/LicenseUtils/CurrentPriceExtension';

// Services
import IssueProfileService from '../../../Services/IssueProfiles/IssueProfilesService';
import BillingService from '../../../Settings/Billing/BillingService';
import SensorService from '../SensorService';

// Forms
import ModuleForm from '../Forms/ModuleForm';
import SensorBasicsFormComponent from '../Forms/SensorBasicsForm/SensorBasicsFormComponent';
import MetadatasForm from '../Forms/MetadatasForm';
import LimitAlarmsForm from '../Forms/LimitAlarmForm/LimitAlarmsForm';
import SensorIssuesFormComponent from '../Forms/SensorIssueForm/SensorIssuesFormComponent';
import SensorSummaryFormComponent from '../Forms/SensorSummary/SensorSummaryFormComponent';
import ConfigurationTemplatesForm from '../Forms/ConfigurationTemplatesForm';

// Tools & Utils
import {
	getFreeChannelDataFromModule,
	getFreeChannelsFromModule,
	getUsedChannelDataFromModule,
	isFirstSensorForChannelUsed,
} from '../Utils/DiscoverModule';
import Shared from '../../../Shared/Shared';
import {ViewHeader} from '../../../Common';
import LiberoGxMapper from '../../../Forms/Mappers/LiberoGMapper/LiberoGxMapper';
import EcologProGxMapper from '../../../Forms/Mappers/EcologProGMapper/EcologProGxMapper';
import {PredictiveForm, PredictiveTrans} from '../../../Wizard/';
import {FormNavigation} from '../../../Wizard/Elements';
import {Button, Col, Row} from 'antd';
import {PredictiveFormState} from '../../../Wizard/Forms/Predictive/PredictiveForm';
import {AddModuleResponse} from '../../../../common/services/SensorService/AddModuleResponse';
import {FeatureFlag} from '../../../../common/helpers';
import {Template} from '../../../../common/types/DbModel';
import {DeviceUtils} from '../../../../common/util';
import {LimitAlarmFormState} from '../Forms/LimitAlarmForm/LimitAlarmFormState';
import {ExportFormStateAndSteps, GetDefaultLoggingInterval, SetUpPredictive, WizardNavigationUtil} from '../../../Wizard/Util';
import {GetChannelTypes} from '../Helpers/GetChannelTypes';
import {SmsAddonRequired} from '../Helpers/SmsAddonRequired';
import {
	GetLicenseOffering,
	PrepareLicenseOfferingFromLineItemTypes,
	SetUpCheckoutSession,
	SetUpSubscriptionUpdate,
} from '../../../Wizard/Util/LicensingUtils';
import {InternalProductCategories} from '../../../../common/util/LicenseUtils/LineItemConstants';
import {LicensingExtension} from '../../../../common/util/LicenseUtils/LicensingExtension';
import {TemplateService} from '../../../../common/services/TemplateService';
import {CalculateConfigurationChecksum} from '../../../../common/services/WebserverServicePro/DeviceService';
import {ReportService} from '../../../../common/services/ReportService';
import {prepareConnectModuleRequest} from '../Helpers/SensorConnectRequestBuilder';
import {IssueAlarmFormState} from '../Forms/SensorIssueForm/IssueAlarmFormState';

dayjs.extend(duration);

const LiberoGxWizard = LiberoGxMapper(FormBuilder);
const EcologProGxWizard = EcologProGxMapper(FormBuilder);

class AddSensor extends Component<AddSensorProps, AddSensorState> {
	declare context: React.ContextType<typeof MultiContext>;
	static contextType = MultiContext;

	constructor(props: AddSensorProps | Readonly<AddSensorProps>) {
		super(props);
		this.state = {
			formStates: {
				predictiveForm: undefined,
				limitAlarmForms: [],
				issueAlarmForm: undefined,
			},
			loading: false,
			templateName: '',
			usersAlarmNotification: [],
			current_step: 0,
			steps: GetSensorSteps(false),
			uncounted_steps: 1,
			offering: undefined,
			hasSubscription: false,
			licenseOffering: {
				baseLicenses: [],
				loggingIntervals: [],
				limitEmail1: undefined,
				limitEmail2: undefined,
				limitSms1: undefined,
				limitSms2: undefined,
				issueEmail: undefined,
				issueSms: undefined,
				retirement1: undefined,
				retirement2: undefined,
			},
			licenseOfferingResult: undefined,
			moduleForm: {
				serial_number: '',
			},
			sensorBasicsFormSettings: undefined,
			sensorBasicsForm: {
				communication_interval: null,
				communication_mode: undefined,
				last_run_only: false,
				optimized_communication_behavior: false,
				disableChangeLogging: true,
				isConfiguration: false,
				sensor_name: '',
				isNextButtonDisabled: false,
				customer_id: '',
				logging_interval: 600,
				unit: this.context?.AuthContext?.Organization?.DefaultTemperature,
				defaultSettingUnit: this.context?.AuthContext?.Organization?.DefaultTemperature,
				memo: '',
				firmware: '',
				active: false,
				moduleData: null,
				currentModuleChannels: null,
				selectedChannel: null,
			},
			metadatasForm: {
				sensor_name: '',
				customer_id: '',
				memo: '',
			},
			currentLimitAlarmsFormIndex: 0,
			summaryForm: {
				start_sensor: false,
				vouchers: [],
				offering: undefined,
				errorMessage: '',
				terms_of_use: false,
				disable_sensor_update_msg: '',
			},
			additionalConfiguration: {
				has_additional_configuration: false,
				configuration_templates: null,
				configuration_template: null,
				configuration_template_result: null,
				communication_mode_templates: null,
				additional_configuration_forms: null,
			},
			sensorIndex: null,
			sensors: [],
			currentChannelIndex: undefined,
			currentValueIndex: undefined,
			isLiberoG: undefined,
		};
	}

	componentDidMount() {
		this.getLicenseOfferings();
	}

	handleSensorTypeSelected = type => {
		switch (type) {
			case 1:
				this.setState({current_step: 0});
				break;
			case 2:
				alert('not implemented');
				break;
			default:
				this.setState({current_step: 0});
		}
	};

	handleSerialNumberChange = event => {
		const input = event.target;

		if (!SerialNumberInfo.isSupported(input.value)) {
			return;
		}

		if (!SerialNumberInfo.isValidLength(input.value)) {
			return;
		}

		this.setState(prevState => ({
			...prevState,
			moduleForm: {
				...prevState.moduleForm,
				serial_number: input.value.toUpperCase(),
			},
			sensorBasicsForm: {
				...prevState.sensorBasicsForm,
				sensor_name: input.value.toUpperCase(),
				customer_id: input.value.toUpperCase(),
			},
			metadatasForm: {
				...prevState.metadatasForm,
				sensor_name: input.value.toUpperCase(),
				customer_id: input.value.toUpperCase(),
			},
			isLiberoG: SerialNumberInfo.isValidLiberoG(input.value),
			isEcologProG: SerialNumberInfo.isValidEcologProG(input.value),
		}));
	};

	handleSensorBasicsInputChange = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;
		if (value) {
			this.setState(prevState => ({
				...prevState,
				sensorBasicsForm: {
					...prevState.sensorBasicsForm,
					['fast_communication_interval']: 60,
					[input.name]: value,
				},
			}));
		} else {
			this.setState(prevState => ({
				...prevState,
				sensorBasicsForm: {
					...prevState.sensorBasicsForm,
					[input.name]: value,
				},
			}));
		}
	};

	handleCommunicationIntervalChange = event => {
		let value = event;
		this.setState(prevState => ({
			...prevState,
			sensorBasicsForm: {
				...prevState.sensorBasicsForm,
				communication_interval: value,
			},
		}));
	};

	handleMetadatasInputChange = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;
		this.setState(prevState => ({
			...prevState,
			metadatasForm: {
				...prevState.metadatasForm,
				[input.name]: value,
			},
		}));
	};

	handleCommunicationModeChange = event => {
		this.setState(prevState => ({
			...prevState,
			sensorBasicsForm: {
				...prevState.sensorBasicsForm,
				communication_mode: event,
			},
		}));
	};

	handleLoggingIntervalChange = event => {
		this.setState(
			prevState => ({
				sensorBasicsForm: {
					...prevState.sensorBasicsForm,
					logging_interval: event,
				},
			}),
			this.getLicenseOfferings
		);
	};

	handleFastCommunicationIntervalChange = event => {
		this.setState(prevState => ({
			sensorBasicsForm: {
				...prevState.sensorBasicsForm,
				fast_communication_interval: event,
			},
		}));
	};

	handleUnitChange = event => {
		if (!this.state.additionalConfiguration.has_additional_configuration) {
			this.setState(prevState => ({
				...prevState,
				sensorBasicsForm: {
					...prevState.sensorBasicsForm,
					unit: event,
				},
			}));
		} else {
			this.setState(prevState => ({
				sensorBasicsForm: {
					...prevState.sensorBasicsForm,
					unit: event,
					temperature_display_unit: Shared.getSensorUnit(event).replace(/^°+/g, ''),
				},
			}));
		}
	};

	setOfferingObject = offeringObject => {
		this.setState({offering: offeringObject});
		if (offeringObject.voucher_rest > 0) {
			this.setState({
				summaryForm: {
					...this.state.summaryForm,
					disable_sensor_update_msg:
						'Update is not possible because voucher not fully redeemable (' +
						offeringObject.voucher_rest +
						' CHF remaining)' +
						'. Please use prepaid credit.',
				},
			});
		} else {
			this.setState({
				summaryForm: {
					...this.state.summaryForm,
					disable_sensor_update_msg: '',
				},
			});
		}
	};

	getOffering = (redeem_prepaid?: boolean, limitAlarmDraft?: LimitAlarmFormState[], issueAlarmDraft?: IssueAlarmFormState) => {
		let that = this;

		this.setState({offering: undefined});

		let payload = {
			serial_number: null,
			logging_interval: this.state.sensorBasicsForm.logging_interval,
			sms_active: SmsAddonRequired(
				this.state.moduleForm.serial_number,
				this.state.sensorBasicsForm.selectedChannel?.selectedChannel,
				this.state.sensorBasicsForm.selectedChannel?.selectedValueIndex,
				limitAlarmDraft || this.state.formStates?.limitAlarmForms,
				issueAlarmDraft || this.state.formStates?.issueAlarmForm
			),
			vouchers: this.state.summaryForm.vouchers,
			redeem_prepaid: redeem_prepaid || false,
			is_libero_g: !!this.state.additionalConfiguration.has_additional_configuration,
		};

		if (
			this.state.templateName !== null &&
			this.state.templateName !== undefined &&
			this.state.templateName === MODULE_FAMILIES.ECOLOG_PRO_G
		) {
			payload.is_libero_g = false;
		}

		// payload.serial_number= this.state.sensor.serial_number;

		//LUE
		if (this.state.moduleForm) payload.serial_number = this.state.moduleForm.serial_number;

		BillingService.getOffering(payload, RequestLogger.createLogData('add-sensor', 'get-offering'))
			.then(function (response) {
				that.setOfferingObject(response.data);
			})
			.catch(function (error) {
				console.error(error);
			});
	};

	back = () => {
		this.updateCurrentStep(WizardNavigationUtil.GetNextStep(this.state, 'backward'));

		// set terms of use and disable update warning message to false, when go back 1 step
		this.setState({
			summaryForm: {
				...this.state.summaryForm,
				disable_sensor_update_msg: '',
			},
		});
	};

	next = () => {
		let nextStep = this.state.current_step;

		if (nextStep === 0 && Array.isArray(this.state.additionalConfiguration?.configuration_templates)) {
			const firstTemplateId = this.state.additionalConfiguration.configuration_templates[0].id;
			this.handleConfigurationTemplateForm(firstTemplateId, true);
		}

		if (nextStep === 0 && this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)) {
			let hasSubscription = false;
			this.state.sensorBasicsForm.moduleData.module.module_id
				? LicensingExtension.HasSubscription(this.state.sensorBasicsForm.moduleData.module.module_id?.toString()).then(
						hasSubscription => this.setState({hasSubscription: hasSubscription})
				  )
				: this.setState({hasSubscription: hasSubscription});
		}

		this.updateCurrentStep(WizardNavigationUtil.GetNextStep(this.state, 'forward'));
	};

	limitAlarmsFormBack = () => {
		if (this.state.currentLimitAlarmsFormIndex === 0) {
			this.back();
		} else {
			this.setState(prevState => ({currentLimitAlarmsFormIndex: prevState.currentLimitAlarmsFormIndex - 1}));
		}
	};

	limitAlarmsFormNext = () => {
		if (
			this.state.currentLimitAlarmsFormIndex ===
			GetChannelTypes(this.state.sensorBasicsForm, DeviceUtils.GetDeviceFamily(this.state.moduleForm.serial_number)).length - 1
		) {
			this.next();
		} else {
			this.setState(prevState => ({currentLimitAlarmsFormIndex: prevState.currentLimitAlarmsFormIndex + 1}));
		}
	};

	exportSensorConfigAsJson = async (configName: string, creditCard: any) => {
		const data = await prepareConnectModuleRequest(
			this.state.sensorBasicsForm,
			this.state.moduleForm,
			this.state.formStates,
			this.state.summaryForm,
			this.state.offering,
			this.state.additionalConfiguration,
			this.state.metadatasForm,
			creditCard
		);

		const element = document.createElement('a');
		element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data, null, 2)));
		element.setAttribute('download', `${configName}.json`);

		element.style.display = 'none';
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	};

	calculateCheckSum = async (): Promise<string> => {
		const data = await prepareConnectModuleRequest(
			this.state.sensorBasicsForm,
			this.state.moduleForm,
			this.state.formStates,
			this.state.summaryForm,
			this.state.offering,
			this.state.additionalConfiguration,
			this.state.metadatasForm
		);

		return data?.additional_configuration?.config_id ?? (await CalculateConfigurationChecksum(data));
	};

	exportSensorConfigAsReport = async () => {
		const data = await prepareConnectModuleRequest(
			this.state.sensorBasicsForm,
			this.state.moduleForm,
			this.state.formStates,
			this.state.summaryForm,
			this.state.offering,
			this.state.additionalConfiguration,
			this.state.metadatasForm
		);
		await ReportService.DownloadDeviceConfigurationReport(data);
	};

	uploadConfigAsTemplate = async (configName: string, creditCard: any) => {
		const orgId = this.context.AuthContext.Organization.Id;
		const serialNumberPrefix = DeviceUtils.GetSerialNumberPrefix(this.state.moduleForm.serial_number);
		const data = await prepareConnectModuleRequest(
			this.state.sensorBasicsForm,
			this.state.moduleForm,
			this.state.formStates,
			this.state.summaryForm,
			this.state.offering,
			this.state.additionalConfiguration,
			this.state.metadatasForm,
			creditCard
		);

		await TemplateService.UploadTemplate(configName, serialNumberPrefix, data, orgId);
	};

	getLicenseOfferings = (redeem_prepaid: boolean = undefined) => {
		this.getOffering(redeem_prepaid);
	};

	buySensor = async creditCard => {
		const data = await prepareConnectModuleRequest(
			this.state.sensorBasicsForm,
			this.state.moduleForm,
			this.state.formStates,
			this.state.summaryForm,
			this.state.offering,
			this.state.additionalConfiguration,
			this.state.metadatasForm,
			creditCard
		);

		SensorService.addModule(data, RequestLogger.createLogData('add-module', 'add-module', 'onClick'))
			.then(response => {
				const addModuleResponse = response.data as AddModuleResponse;

				SetUpPredictive(
					this.context?.AuthContext?.Organization?.Uuid,
					this.state.formStates.predictiveForm,
					addModuleResponse.result_all[0].sensor.id,
					this.state.sensorBasicsForm.logging_interval
				);

				const successForwardUrl =
					'/connectModule/' +
					addModuleResponse.sensor.id +
					'/' +
					this.state.moduleForm.serial_number +
					'/' +
					addModuleResponse.module.prefix;

				if (this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)) {
					if (this.state.sensorBasicsForm.moduleData.module.used_channels !== null && this.state.hasSubscription) {
						SetUpSubscriptionUpdate(
							addModuleResponse.module.id.toString(),
							this.state.licenseOffering,
							successForwardUrl,
							this.props.history,
							this.context.AccessContext.user.user_settings.devicesViewSettings
						);
					} else {
						SetUpCheckoutSession(
							addModuleResponse.module.id.toString(),
							this.state.licenseOffering,
							successForwardUrl,
							this.props.history,
							this.context.AccessContext.user.user_settings.devicesViewSettings
						);
					}
				} else {
					if (addModuleResponse.module.paired) {
						Message.success('Connected', 'Module connected successfully!');
						const DevicesViewSettings = this.context.AccessContext.user.user_settings.devicesViewSettings;
						this.props.history.push({pathname: DevicesViewSettings.view});
					} else {
						this.props.history.push({
							pathname: successForwardUrl,
						});
					}
				}
			})
			.catch(error => {
				RegistryHelper.logPublishAndToast(error, pubsub.publish.bind(null, 'catchRegistryExceptions'), 'adding');
			});
	};

	buyAndStart = creditCardObject => {
		let priceObject = this.getCurrentPriceObject();
		switch (this.state.templateName) {
			case MODULE_FAMILIES.LIBERO_G:
				showLiberoGWarning(priceObject, this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing), async () => {
					await this.buySensor(creditCardObject);
				});
				break;
			default:
				showPriceWarning(priceObject, async () => {
					await this.buySensor(creditCardObject);
				});
				break;
		}
	};

	updateCurrentStep = step => {
		this.setState({current_step: step});
		pubsub.publish('addNewSensorStepChanged', {current_step: step});
		this.getLicenseOfferings();
	};

	close = e => {
		const DevicesViewSettings = this.context.AccessContext.user.user_settings.devicesViewSettings;
		this.props.history.push(DevicesViewSettings.view);
		e.preventDefault();
	};

	handleTermsOfUse = () => {
		this.setState({
			summaryForm: {
				...this.state.summaryForm,
				terms_of_use: !this.state.summaryForm.terms_of_use,
			},
		});
	};

	handleModuleChannels = moduleInformation => {
		// find channels, that are not used
		const freeChannelsToUse = getFreeChannelsFromModule(
			moduleInformation.module.channels,
			moduleInformation.module.used_channels,
			moduleInformation.channel_types
		);

		// store used and free channels for this module
		const channelCollection = {channelsUsed: [], channelsFree: []};
		channelCollection.channelsFree = getFreeChannelDataFromModule(
			freeChannelsToUse,
			moduleInformation.channel_types,
			moduleInformation.units,
			moduleInformation.module.used_channels
		);
		channelCollection.channelsUsed = getUsedChannelDataFromModule(
			freeChannelsToUse,
			moduleInformation.module.used_channels,
			moduleInformation.channel_types,
			moduleInformation.units
		);

		this.sortChannels(channelCollection.channelsFree);

		this.sortChannels(channelCollection.channelsUsed);

		// set first as default selected
		let selectedChannel = channelCollection.channelsFree[0];

		// get sensor unit
		let unit = this.state.sensorBasicsForm.unit;

		// no more free channels and sensor is running/registered
		if ((!channelCollection.channelsFree.length && moduleInformation.module.registered) || !channelCollection.channelsFree.length) {
			selectedChannel = channelCollection.channelsUsed[0];
			unit = channelCollection.channelsUsed[0].channel_types[0].units_id;
		}

		let type = selectedChannel.channel_types[0];

		selectedChannel.selectedChannel = selectedChannel.channel_number;
		selectedChannel.selectedValueIndex = selectedChannel.channel_types[0].value_index;
		selectedChannel.selected = selectedChannel.channel_number + '-' + selectedChannel.channel_types[0].value_index;

		// is default sensor unit id  available in selected channel type?
		// if not, set first as default selection

		selectedChannel.channel_types.some(ct => {
			if (selectedChannel.selectedValueIndex === ct.value_index) {
				if (!ct.units.find(u => u.id === unit)) {
					unit = ct.units[0].id;
					type = ct;
					return true;
				}
			}
			return null;
		});

		const isFirstSensorUsed = isFirstSensorForChannelUsed(moduleInformation.module);
		const hasConfigurationTemplates =
			!!moduleInformation.module.configuration_templates && moduleInformation.module.configuration_templates.length > 0;

		const configTemplateName = findFamilyTypeFromTemplateName(
			moduleInformation.module.configuration_templates !== undefined ? moduleInformation.module.configuration_templates[0].name : null
		);

		const sensorBasicsFormSettings = getSensorBasicsFormSettingsForConfiguration(this.state.moduleForm.serial_number);

		this.setState(
			prevState => ({
				...prevState,
				currentChannelIndex: 0,
				currentValueIndex: 0,
				templateName: configTemplateName,
				sensorBasicsFormSettings: sensorBasicsFormSettings,
				sensorBasicsForm: {
					...prevState.sensorBasicsForm,
					logging_interval: isFirstSensorUsed
						? moduleInformation.module.used_channels[0].logging_interval
						: this.state.sensorBasicsForm.logging_interval,
					disableChangeLogging: isFirstSensorUsed,
					isSensorRetired: moduleInformation.module.is_sensor_retired,
					isNextButtonDisabled: !channelCollection.channelsFree.length || moduleInformation.module.is_sensor_retired,
					unit: hasConfigurationTemplates ? 2 : unit, //LiberoG Sensors can only have temp unit selected
					currentModuleChannels: channelCollection,
					moduleData: moduleInformation,
					selectedChannel: selectedChannel,
					communication_mode: hasConfigurationTemplates
						? configTemplateName === MODULE_FAMILIES.LIBERO_G
							? sensorBasicsFormSettings.showCommunicationMode
								? moduleInformation.module.communication_mode_templates[1].index
								: null
							: moduleInformation.module.communication_mode_templates[0].index
						: null,
				},
				additionalConfiguration: {
					...prevState.additionalConfiguration,
					has_additional_configuration: hasConfigurationTemplates,
					configuration_templates: hasConfigurationTemplates ? moduleInformation.module.configuration_templates : null,
					configuration_template: hasConfigurationTemplates ? new Map<string, Template>() : null,
					configuration_template_result: hasConfigurationTemplates ? {} : null,
					communication_mode_templates: hasConfigurationTemplates ? moduleInformation.module.communication_mode_templates : null,
					additional_configuration_forms: hasConfigurationTemplates ? {} : null,
				},
				steps: GetSensorSteps(hasConfigurationTemplates, false, false, configTemplateName),
				uncounted_steps: hasConfigurationTemplates ? 2 : 1,
			}),
			this.next
		);
	};

	sortChannels = channels => {
		channels.map(channel => {
			for (let i = 0; i < channel.channel_types.length; i++) {
				if (channel.channel_types[i].symbolValue.includes('humidity')) {
					let tmp = channel.channel_types[i];
					channel.channel_types[i] = channel.channel_types[channel.channel_types.length - 1];
					channel.channel_types[channel.channel_types.length - 1] = tmp;
				} else if (
					channel.channel_types[i].symbolValue.includes('temperature') &&
					!channel.channel_types[i].symbolValue.includes('external')
				) {
					let tmp = channel.channel_types[i];
					channel.channel_types[i] = channel.channel_types[0];
					channel.channel_types[0] = tmp;
				}
			}
		});
	};

	handleChannelChange = value => {
		const channel = parseInt(value.split('-')[0]);
		const valueIndex = parseInt(value.split('-')[1]);

		const selected = this.state.sensorBasicsForm.currentModuleChannels.channelsFree.find(obj => obj.channel_number === channel);
		selected.selectedChannel = channel;
		selected.selectedValueIndex = valueIndex;
		selected.selected = channel + '-' + valueIndex;

		let type = selected.channel_types[0];
		let defaultUnit = this.state.sensorBasicsForm.defaultSettingUnit;
		selected.channel_types.forEach(channel => {
			if (channel.value_index === valueIndex) {
				const hasDefaultUnit = channel.units.find(unit => unit.id === this.state.sensorBasicsForm.defaultSettingUnit);
				if (!hasDefaultUnit) {
					type = channel;
					defaultUnit = channel.units[0].id;
				}
			}
		});

		if (this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)) {
			PrepareLicenseOfferingFromLineItemTypes(
				this.state.moduleForm.serial_number,
				selected.selectedChannel,
				selected.selectedValueIndex,
				this.state.licenseOffering,
				[
					InternalProductCategories.BaseIPC,
					InternalProductCategories.LoggingIntervalIPC,
					InternalProductCategories.LimitProfileEmailIPC,
					InternalProductCategories.LimitProfileSmsIPC,
					InternalProductCategories.IssueProfileEmailIPC,
					InternalProductCategories.IssueProfileSmsIPC,
				],
				this.state.currentLimitAlarmsFormIndex,
				this.state.formStates?.limitAlarmForms?.at(this.state.currentLimitAlarmsFormIndex),
				this.state.formStates?.issueAlarmForm,
				this.state.sensorBasicsForm.logging_interval
			).then(result => {
				this.setState(prevState => ({...prevState, licenseOffering: result}), this.getLicenseOfferings);
			});
		}

		this.setState(prevState => {
			prevState.sensorBasicsForm.unit = defaultUnit;
			prevState.sensorBasicsForm.selectedChannel = selected;

			return {...prevState};
		}, this.getLicenseOfferings);
	};

	handleConfigurationTemplateForm = (id, skipped = false) => {
		SensorService.configuration_template(id, RequestLogger.createLogData('add-sensor', 'load-configuration-template', 'onLoad')).then(
			response => {
				const configTemplateName = findFamilyTypeFromTemplateName(response.data.name);
				this.setState(prevState => ({
					...prevState,
					sensorBasicsForm: {
						...prevState.sensorBasicsForm,
						communication_interval: 120,
						fast_communication_interval: 60,
						logging_interval: GetDefaultLoggingInterval(this.state.moduleForm.serial_number),
					},
					additionalConfiguration: {
						...prevState.additionalConfiguration,
						configuration_template: response.data.template,
						configuration_template_result: {
							configuration_templates_id: id,
						},
					},
					templateName: configTemplateName,
					steps: GetSensorSteps(true, response.data.template, false, configTemplateName),
				}));

				if (skipped === false) this.next();
			}
		);
	};

	handleConfigFormChange = value => {
		this.setState(prevState => ({
			...prevState,
			additionalConfiguration: {
				...prevState.additionalConfiguration,
				configuration_template_result: {
					...prevState.additionalConfiguration.configuration_template_result,
					...value,
				},
			},
		}));
	};

	handleConfigFormError(section, errors, sectionErrors = {}) {
		const hasErrors = Object.values(errors).some(val => val);

		this.setState(prevState => {
			const prevSectionErrors = prevState.additionalConfiguration.additional_configuration_forms[`${section}_errors`] || {};
			const hasSectionErrors = Object.values({...prevSectionErrors, ...sectionErrors}).some(val => !!val);

			return {
				additionalConfiguration: {
					...prevState.additionalConfiguration,
					additional_configuration_forms: {
						...prevState.additionalConfiguration.additional_configuration_forms,
						[`${section}_is_valid`]: !hasErrors && !hasSectionErrors,
						[`${section}_errors`]: {...prevSectionErrors, ...sectionErrors},
					},
				},
			};
		});
	}

	handleConfigFormSubmit = (e, section) => {
		e.preventDefault();

		const additional_configuration_forms = this.state.additionalConfiguration.additional_configuration_forms;
		if (additional_configuration_forms[`${section}_is_valid`] !== false) {
			this.next();
		} else {
			this.setState(prevState => ({
				additionalConfiguration: {
					...prevState.additionalConfiguration,
					additional_configuration_forms: {
						...prevState.additionalConfiguration.additional_configuration_forms,
						[`${section}_is_submitted`]: true,
					},
				},
			}));
		}
	};

	maxIndexInMap = (objectMap: Map<string, {index: number}>): number => {
		return Math.max(...Object.values(objectMap).map(o => o.index));
	};

	isLastConfigurationTemplateStep = (index: number): boolean => {
		return index === this.maxIndexInMap(this.state.additionalConfiguration.configuration_template);
	};

	getCurrentPriceObject = (): CurrentPriceResult => {
		let currentPriceProps: CurrentPriceProps = {
			totalAmount: this.state.licenseOfferingResult?.totalAmount,
			actualPrice: this.state.offering?.actual_price,
			currency: this.state.licenseOfferingResult?.currency,
			interval: this.state.licenseOfferingResult?.lineItems[0]?.interval,
		};
		return CurrentPriceExtension.getCurrentPrice(currentPriceProps, this.context.FeatureFlagsContext);
	};

	saveLimitAlarmStateCallback = (index: number, limitAlarmFormState: LimitAlarmFormState) => {
		const updateState = ExportFormStateAndSteps(this.state, index, limitAlarmFormState);

		let formStates = this.state.formStates;
		formStates.limitAlarmForms[index] = limitAlarmFormState;

		this.setState(prev => ({...prev, ...updateState, formStates: formStates}));
	};

	onLimitAlarmFormStateChange = (index: number, limitAlarmFormState: LimitAlarmFormState) => {
		const limitAlarmsDraft = structuredClone(this.state.formStates?.limitAlarmForms);

		if (limitAlarmFormState.alarmLimit && limitAlarmFormState.recipients) limitAlarmsDraft[index] = limitAlarmFormState;

		this.getOffering(null, limitAlarmsDraft);
	};

	saveIssueAlarmStateCallback = (issueAlarmFormState: IssueAlarmFormState) => {
		let formStates = this.state.formStates;
		formStates.issueAlarmForm = issueAlarmFormState;

		this.setState(prev => ({...prev, formStates: formStates}));
	};

	onIssueAlarmFormStateChange = (issueAlarmFormState: IssueAlarmFormState) => {
		this.getOffering(null, null, issueAlarmFormState);
	};

	getNewSensorSummarySettings(serialNumber: string) {
		const config = getSensorSummarySettingsForConfiguration(serialNumber);
		config.sensorBasics.showFirmware = false;
		return config;
	}

	render() {
		return (
			<>
				<ViewHeader knowledgeHelpId={'011'} heading={'New Sensor'}>
					<Col>
						<Button type={'primary'} onClick={this.close}>
							<em className="fsInButton elpro-Close fa-fw" />
							<span>
								<Trans i18nKey={'buttons.close'} />
							</span>
						</Button>
					</Col>
				</ViewHeader>
				<ContentWrapper>
					<div style={{paddingRight: '85px'}}>
						{
							<Col>
								{this.state.current_step >= 0 && (
									<RadialSteps
										current_step={this.state.current_step}
										steps={this.state.steps}
										uncounted_steps={this.state.uncounted_steps || 0}
									/>
								)}
							</Col>
						}

						{this.state.current_step < 0 ? (
							<div>
								<Row>
									<NewModuleCard type={1} onClicked={this.handleSensorTypeSelected} />
									<NewModuleCard type={2} onClicked={this.handleSensorTypeSelected} />
								</Row>
							</div>
						) : null}

						{this.state.current_step === 0 && (
							<ModuleForm
								moduleForm={this.state.moduleForm}
								handleSerialNumberChange={this.handleSerialNumberChange}
								handleModuleChannels={this.handleModuleChannels}
								onBack={this.back}
								onNext={this.next}
							/>
						)}

						{((!this.state.additionalConfiguration.has_additional_configuration && this.state.current_step === 1) ||
							(this.state.additionalConfiguration.has_additional_configuration && this.state.current_step === 2)) && (
							<SensorBasicsFormComponent
								isNextButtonDisabled={this.state.sensorBasicsForm.isNextButtonDisabled}
								lastRunOnly={this.state.sensorBasicsForm.last_run_only}
								optimizedCommunicationBehavior={this.state.sensorBasicsForm.optimized_communication_behavior}
								sensorBasicsForm={this.state.sensorBasicsForm}
								handleChannelChange={this.handleChannelChange}
								handleSensorBasicsInputChange={this.handleSensorBasicsInputChange}
								handleLoggingIntervalChange={this.handleLoggingIntervalChange}
								handleUnitChange={this.handleUnitChange}
								currentPrice={this.getCurrentPriceObject()}
								onBack={this.back}
								onNext={this.next}
								isReRun={false}
								handleCommunicationIntervalChange={this.handleCommunicationIntervalChange}
								hasConfigurationTemplate={this.state.additionalConfiguration.has_additional_configuration}
								communicationModeTemplates={this.state.additionalConfiguration.communication_mode_templates}
								handleCommunicationModeChange={this.handleCommunicationModeChange}
								handleFastCommunicationIntervalChange={this.handleFastCommunicationIntervalChange}
								templateName={this.state.templateName}
								serialNumber={this.state.moduleForm.serial_number}
								sensorBasicsFormSettings={this.state.sensorBasicsFormSettings}
							/>
						)}

						{this.state.additionalConfiguration.has_additional_configuration &&
							this.state.templateName !== null &&
							this.state.templateName === MODULE_FAMILIES.ECOLOG_PRO_G &&
							this.state.current_step === 3 && (
								<MetadatasForm
									metadatasForm={this.state.metadatasForm}
									handleMetadatasInputChange={this.handleMetadatasInputChange}
									onBack={this.back}
									onNext={this.next}
									hasConfigurationTemplate={this.state.additionalConfiguration.has_additional_configuration}
								/>
							)}

						{this.state.additionalConfiguration.has_additional_configuration && this.state.current_step === 1 && (
							<ConfigurationTemplatesForm
								configurationTemplate={
									!!this.state.additionalConfiguration
										? this.state.additionalConfiguration.configuration_template_result['configuration_templates_id']
										: ''
								}
								configurationTemplates={this.state.additionalConfiguration.configuration_templates}
								handleConfigurationTemplateForm={this.handleConfigurationTemplateForm}
								onBack={this.back}
								onNext={this.next}
							/>
						)}

						{this.state.additionalConfiguration.has_additional_configuration &&
							this.state.templateName !== null &&
							this.state.templateName === MODULE_FAMILIES.LIBERO_G &&
							Object.entries(this.state.additionalConfiguration.configuration_template).map(
								([sectionKey, section], i) =>
									this.state.current_step === section.index && (
										<form key={i} name={`${sectionKey}Form`} onSubmit={e => this.handleConfigFormSubmit(e, sectionKey)}>
											<FormNavigation loading={this.state.loading} />
											<Row>
												<Col span={24}>
													<LiberoGxWizard
														name={sectionKey}
														schema={this.state.additionalConfiguration.configuration_template}
														value={this.state.additionalConfiguration.configuration_template_result || {}}
														formState={this.state.additionalConfiguration.additional_configuration_forms}
														coreData={{
															sensorBasicsForm: this.state.sensorBasicsForm,
															limitAlarmForms: this.state.formStates.limitAlarmForms,
														}}
														onChange={e => this.handleConfigFormChange(e)}
														onError={(e, msg) => this.handleConfigFormError(sectionKey, e, msg)}
														isLastStep={this.isLastConfigurationTemplateStep(section.index)}
													/>
												</Col>
											</Row>
											<FormNavigation onBack={this.back} loading={this.state.loading} />
										</form>
									)
							)}

						{this.state.additionalConfiguration.has_additional_configuration &&
							this.state.templateName !== null &&
							this.state.templateName === MODULE_FAMILIES.ECOLOG_PRO_G &&
							Object.entries(this.state.additionalConfiguration.configuration_template).map(
								([sectionKey, section], i) =>
									this.state.current_step === section.index && (
										<form key={i} name={`${sectionKey}Form`} onSubmit={e => this.handleConfigFormSubmit(e, sectionKey)}>
											<FormNavigation loading={this.state.loading} />
											<EcologProGxWizard
												name={sectionKey}
												schema={this.state.additionalConfiguration.configuration_template}
												value={this.state.additionalConfiguration.configuration_template_result || {}}
												formState={this.state.additionalConfiguration.additional_configuration_forms}
												coreData={{
													sensorBasicsForm: this.state.sensorBasicsForm,
													metadatasForm: this.state.metadatasForm,
													limitAlarmForms: this.state.formStates.limitAlarmForms,
												}}
												onChange={e => this.handleConfigFormChange(e)}
												onError={(e, msg) => this.handleConfigFormError(sectionKey, e, msg)}
												isLastStep={this.isLastConfigurationTemplateStep(section.index)}
											/>

											<FormNavigation onBack={this.back} loading={this.state.loading} />
										</form>
									)
							)}

						{((!this.state.additionalConfiguration.has_additional_configuration && this.state.current_step === 2) ||
							(this.state.additionalConfiguration.has_additional_configuration && this.state.current_step === 4)) &&
							GetChannelTypes(
								this.state.sensorBasicsForm,
								DeviceUtils.GetDeviceFamily(this.state.moduleForm.serial_number)
							).map(
								(channelType, idx: number) =>
									idx == this.state.currentLimitAlarmsFormIndex && (
										<LimitAlarmsForm
											key={idx}
											sensorIndex={this.state.sensorBasicsForm.selectedChannel.channel_number + idx - 1}
											channelType={channelType}
											currentPrice={this.getCurrentPriceObject()}
											onBack={this.limitAlarmsFormBack}
											onNext={this.limitAlarmsFormNext}
											interval={this.state.sensorBasicsForm.logging_interval}
											unit={
												channelType.units.length === 1 ? channelType.units[0].id : this.state.sensorBasicsForm.unit
											}
											initialState={this.state.formStates.limitAlarmForms?.at(idx)}
											saveStateCallback={limitAlarmFormState =>
												this.saveLimitAlarmStateCallback(idx, limitAlarmFormState)
											}
											moduleSerialNumber={this.state.moduleForm.serial_number}
											onFormStateChange={limitAlarmFormState =>
												this.onLimitAlarmFormStateChange(idx, limitAlarmFormState)
											}
										/>
									)
							)}

						{((!this.state.additionalConfiguration.has_additional_configuration && this.state.current_step === 3) ||
							(this.state.additionalConfiguration.has_additional_configuration &&
								this.state.templateName !== null &&
								this.state.current_step === 5)) && (
							<SensorIssuesFormComponent
								initialState={this.state.formStates?.issueAlarmForm}
								moduleSerialNumber={this.state.moduleForm.serial_number}
								currentPrice={this.getCurrentPriceObject()}
								onBack={this.back}
								onNext={this.next}
								onFormStateChange={limitAlarmFormState => this.onIssueAlarmFormStateChange(limitAlarmFormState)}
								saveStateCallback={limitAlarmFormState => this.saveIssueAlarmStateCallback(limitAlarmFormState)}
							/>
						)}

						{this.state.steps[this.state.current_step].text === PredictiveTrans.title &&
							!this.state.steps[this.state.current_step].disabled && (
								<PredictiveForm
									initialState={this.state.formStates.predictiveForm}
									loading={this.state.loading}
									onBack={this.back}
									onNext={this.next}
									outUnit={this.state.sensorBasicsForm.unit}
									saveStateCallback={(predictiveFormState: PredictiveFormState) => {
										this.setState({
											formStates: {
												...this.state.formStates,
												predictiveForm: predictiveFormState,
											},
										});
									}}
								></PredictiveForm>
							)}

						{(this.state.steps[this.state.current_step].text == 'sensorWizard.steps.summary' ||
							this.state.steps[this.state.current_step].text == 'sensorWizard.steps.payment') && (
							<SensorSummaryFormComponent
								formStates={this.state.formStates}
								calculateChecksum={this.calculateCheckSum}
								exportSensorConfigAsJson={this.exportSensorConfigAsJson}
								uploadSensorAsTemplate={this.uploadConfigAsTemplate}
								exportSensorConfigAsReport={this.exportSensorConfigAsReport}
								moduleForm={this.state.moduleForm}
								sensorBasicsForm={this.state.sensorBasicsForm}
								metadatasForm={this.state.metadatasForm}
								configuration_template={this.state.additionalConfiguration.configuration_template}
								additional_configuration={this.state.additionalConfiguration.configuration_template_result}
								communication_mode_templates={this.state.additionalConfiguration.communication_mode_templates}
								summaryForm={this.state.summaryForm}
								offering={this.state.offering}
								onBack={this.back}
								onBuyAndStart={this.buyAndStart}
								handleTermsOfUse={this.handleTermsOfUse}
								hideBuy={
									this.context.FeatureFlagsContext?.includes(FeatureFlag.Licensing)
										? false
										: this.state.additionalConfiguration.has_additional_configuration &&
										  this.state.templateName === MODULE_FAMILIES.LIBERO_G
								}
								getLicenseOfferings={this.getLicenseOfferings}
								licenseOfferingResult={this.state.licenseOfferingResult}
								templateName={this.state.templateName}
								sensorSummarySettings={this.getNewSensorSummarySettings(this.state.moduleForm.serial_number)}
								sensorPaymentMethodSettings={getSensorPaymentMethodSettingsForConfiguration(
									this.state.moduleForm.serial_number
								)}
							/>
						)}
					</div>
				</ContentWrapper>
			</>
		);
	}
}

export default AddSensor;
