import {FormStates} from '../Types';
import {SensorStep} from '../../Dashboard/Sensor/AddSensor/GetSensorSteps';
import {LimitAlarmFormState} from '../../Dashboard/Sensor/Forms/LimitAlarmForm/LimitAlarmFormState';
import {PredictiveTrans} from '../Forms/Predictive/Trans';
import {PredictiveFormState} from '../Forms/Predictive/PredictiveForm';
import {PredictionService} from '../../../common/services';
import {ToBaseUnit} from '../../../common/util/MeasurementValueUtils';
import {ErrorAlert} from '../../Common/Alerts';
import {PositionedSensors} from '../../../common/util/LicenseUtils/LineItemConstants';
import {LineItem} from '../../../common/util/LicenseUtils/LineItem';
import {DeviceUtils} from '../../../common/util';
import {EcologProXGType} from '../../../common/constants/DeviceType';
import {DeviceFamily} from '../../../common/constants';
import * as uuid from 'uuid';
import {IPredictionRequestWithAssessment} from '@elproag/predict';

type FormStateAndSteps = {formStates: FormStates; steps: SensorStep[]};

export function ExportFormStateAndSteps(
	state: FormStateAndSteps,
	index: number,
	limitAlarmFormState: Partial<LimitAlarmFormState>
): FormStateAndSteps {
	let formStates = state.formStates ?? {predictiveForm: null, limitAlarmForms: [], issueAlarmForm: null};
	let limitAlarmFormStates = formStates.limitAlarmForms ?? [];
	limitAlarmFormStates[index] = {...limitAlarmFormStates[index], ...limitAlarmFormState};

	const predictiveEnabled = limitAlarmFormStates.filter(l => l.predictive.enabled).length > 0;

	let steps = state.steps;
	let predictiveStep = steps.find(s => s.text == PredictiveTrans.title);
	if (predictiveStep) {
		predictiveStep.disabled = !predictiveEnabled;
	}

	formStates.predictiveForm = {...formStates.predictiveForm, enabled: predictiveEnabled};
	formStates.limitAlarmForms = limitAlarmFormStates;

	return {steps: steps, formStates: formStates};
}

export function SetUpPredictive(orgUuid: string, predictiveState: PredictiveFormState, sensorRunId: number, loggingInterval: number): void {
	if (!predictiveState?.enabled) {
		return;
	}
	if (!uuid.validate(orgUuid)) throw new Error('invalid uuid');

	const predictionRequest: IPredictionRequestWithAssessment = {
		predictionParameters: {
			containerId: predictiveState.container.id,
			laneId: predictiveState.lane.id,
			samplingIntervalInSeconds: loggingInterval,
			sensorRunId: sensorRunId,
		},
		assessmentParameters: {
			lowerLimit: ToBaseUnit(predictiveState.alarmLimit.LowerLimits[0], predictiveState.alarmLimitOutUnit),
			upperLimit: ToBaseUnit(predictiveState.alarmLimit.UpperLimits[0], predictiveState.alarmLimitOutUnit),
		},
	};

	PredictionService.PostPredictions(orgUuid, predictionRequest)
		.then(r => console.log(r))
		.catch(e => {
			console.error(e);
			ErrorAlert.fire({
				title: 'Error',
				icon: 'error',
				text: 'During the configuration of the prediction service, an error occurred. Please reconfigure the device.',
			}).then();
		});
}

export function IsLineItemForSelectedSensor(lineItem: LineItem, channelNumber: number, valueIndex: number): boolean {
	return lineItem.internalPriceId.endsWith(PositionedSensors[channelNumber][valueIndex]);
}

export function GetDefaultLoggingInterval(serialNumber: string): number {
	let loggingInterval = 600;
	if (
		DeviceUtils.GetDeviceType(serialNumber) === EcologProXGType.OneTHGi ||
		DeviceUtils.GetDeviceFamily(serialNumber) === DeviceFamily.LiberoGx
	) {
		loggingInterval = 900;
	}
	return loggingInterval;
}

export interface QueryParameters {
	variableName: string;
	items: string[];
}

export function PrepareQueryString(objectArray: QueryParameters[]): string {
	try {
		let queryStrings: string[] = [];

		objectArray.forEach(o => {
			o.items.forEach((i: string) => queryStrings.push(`${o.variableName}=${i}`));
		});

		return queryStrings.join('&');
	} catch (error) {
		console.error('Error while preparing query string:', error);
		throw error;
	}
}
