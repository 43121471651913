import React from 'react';
import {Trans} from 'react-i18next';
import {CONSTANTS} from '../../../../../../Shared/Constants/Constants';
import {IssueProfile} from '../../../SensorIssueForm/IssueAlarmFormState';

type IssueAlarmSensorFailureAlarmProps = {
	sensorIssuesForm: IssueProfile;
	show: boolean;
};

export default function IssueAlarmsSensorFailureAlarmComponent(props: IssueAlarmSensorFailureAlarmProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.sensorFailureAlarm'} />
				</td>
				<td>
					{(() => {
						if (props.sensorIssuesForm == null) {
							return CONSTANTS.NOT_AVAILABLE;
						}
						return props.sensorIssuesForm.sensor_failure_alarm ? CONSTANTS.YES : CONSTANTS.NO;
					})()}
				</td>
			</tr>
		)
	);
}
