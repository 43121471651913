import dayjs from 'dayjs';

const GroupAlarmStatusesAccordingToDate = container => {
	if (!Array.isArray(container)) {
		throw new TypeError('Please pass an array as a parameter of GroupAlarmStatusesAccordingToDate!!!');
	}

	if (container.length <= 1) {
		return container;
	}

	const dateMap = new Map<string, {index: string; tstamp: string; desc: Set<string>}>();

	for (const item of container) {
		const date = dayjs(item.tstamp).startOf('minute');
		const dateString = date.toISOString();
		let group = dateMap.get(dateString);

		if (!group) {
			group = {
				index: dateString,
				tstamp: dateString,
				desc: new Set<string>(),
			};
			dateMap.set(dateString, group);
		}
		group.desc.add(item.desc);
	}

	const descriptions = Array.from(dateMap.values()).map(item => ({
		...item,
		index: item.index,
		tstamp: item.tstamp,
		desc: Array.from(item.desc),
	}));

	return descriptions;
};

export default GroupAlarmStatusesAccordingToDate;
