import React from 'react';
import {Trans} from 'react-i18next';
import {mapMissingCommunicationWarningEnum} from '../Helpers/MissingCommunicationWarningEnumMapper';
import {CONSTANTS} from '../../../../../../Shared/Constants/Constants';
import {IssueProfile} from '../../../SensorIssueForm/IssueAlarmFormState';

type IssueAlarmMissingCommunicationWarningEnumProps = {
	sensorIssuesForm: IssueProfile;
	show: boolean;
};

export default function IssueAlarmsMissingCommunicationWarningEnumComponent(props: IssueAlarmMissingCommunicationWarningEnumProps) {
	return (
		props.show && (
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.missingCommunicationWarningEnum'} />
				</td>
				<td>
					{(() => {
						if (props.sensorIssuesForm == null) {
							return CONSTANTS.NOT_AVAILABLE;
						}
						if (!props.sensorIssuesForm.missing_communication_warning) {
							return CONSTANTS.NO;
						}
						return mapMissingCommunicationWarningEnum(props.sensorIssuesForm.missing_communication_warning_enum);
					})()}
				</td>
			</tr>
		)
	);
}
