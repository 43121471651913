import {Select} from 'antd';
import {TILT_WARNING_INITIAL_POSITIONS} from '../../../../Shared/Constants/TiltWarning/TiltWarning';
import React from 'react';

interface TiltWarningInitialPositionSelectProps {
	value: {reference_x: number; reference_y: number; reference_z: number};
	disabled: boolean;
}

export default function TiltWarningInitialPositionSelectComponent(props: Readonly<TiltWarningInitialPositionSelectProps>) {
	const selectedPosition = Object.values(TILT_WARNING_INITIAL_POSITIONS).find(
		v =>
			props.value &&
			v.reference_x === props.value.reference_x &&
			v.reference_y === props.value.reference_y &&
			v.reference_z === props.value.reference_z
	);

	return (
		<Select id="selectTiltWarning" style={{width: '100%'}} value={selectedPosition?.id} disabled={props.disabled}>
			{Object.values(TILT_WARNING_INITIAL_POSITIONS).map(v => (
				<option key={v.id} value={v.id}>
					{v.name}
				</option>
			))}
		</Select>
	);
}
