import React, {MouseEvent, useContext, useEffect, useRef, useState} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Card as CommonCard, ViewHeader} from '../Common';
import {ViewWrapper} from '../Layout';
import {accessPermissions, userRoles} from '../Infrastructure/Authorization/Access';
import {Access} from '../Infrastructure/Authorization/Components';
import {CloseButton} from '../Buttons';
import {AccessContext} from '../Infrastructure/Authorization/Context/AccessContext';
import {useNavigate, useSearchParams} from 'react-router-dom-v5-compat';
import {useParams} from 'react-router-dom';
import {PredictionService, SensorService} from '../../common/services';
import {SensorsAllInformationsViewRow} from '../../common/types/DbModel';
import {LoadingComponent} from '../LoadingComponent';
import {useTranslation} from 'react-i18next';
import {MultiRunDropdown} from './Run/MultiRunDropdown';
import Authentication from '../Infrastructure/Authentication/Authentication';
import {RunInfo} from '../../common/types';
import {DeviceFamily, Paths} from '../../common/constants';
import DateTimeUtils, {formatDayJsInUserTz} from '../Infrastructure/DateTime/DateTimeUtils';
import {AdditionalTimeRange, TimeRangePicker} from '../Common/Filter/TimeRangePicker';
import SensorAnalysisService from './SensorAnalysisService';
import moment from 'moment';
import {
	LOGGER_STATUS_EVENTS,
	OCCURRENCES,
	OTHER_OCCURRENCES,
	REPLACE_SENSOR_EVENTS,
	SENSOR_ISSUE_ALARMS,
	SENSOR_LIMIT_ALARMS,
} from '../Shared/Constants/Chart';
import {AuthContext} from '../../common/context';
import {DeviceUtils} from '../../common/util';
import {Card as AntCard, Flex, Spin} from 'antd';
import {ReportComponent} from './Run/ReportComponent';
import {AlarmStateComponent} from './Run/AlarmStateComponent';
import ChartComponent from './Chart/ChartComponent';
import Statistics from '../Services/Extensions/Statistics';
import {TiltGraph} from '../Tilt/TiltGraph';
import {PredictiveTrans} from '../Wizard';
import {PredictionResultView} from '../Predictive/PredictionResultView';
import {Sensor} from '../Common/Types/Sensor';
import SensorMetaData from './Components/SensorMetaData';
import {MapComponent, MapComponentRef} from './Map/MapComponent';
import {SensorAnalysisStatistics} from './SensorAnalysisStatistics';
import {EventTable} from '../Event/EventTable';
import dayjs from 'dayjs';
import {TimeRange} from '../Infrastructure/DateTime/TimeRange';

export const Card = (props: React.ComponentProps<typeof AntCard>) => <CommonCard size={'small'} {...props} />;

export function SensorAnalysis() {
	const [t] = useTranslation();
	const params = useParams<{id?: string}>();
	const sensorId = parseInt(params.id);
	const navigate = useNavigate();
	const accessContext = useContext(AccessContext);
	const authContext = useContext(AuthContext);
	const showCloseButton = accessContext.user?.access_settings?.layout?.analysis?.close_btn ?? true;
	const [loading, setLoading] = useState(true);
	const [reportLoading, setReportLoading] = useState(false);

	const chart = useRef(null);
	const tiltComponent = useRef(null);
	const mapComponent = useRef<MapComponentRef>(null);

	const searchParamStart = 'timeRangeStart';
	const searchParamEnd = 'timeRangeEnd';
	const [searchParams, setSearchParams] = useSearchParams();

	const [sensor, setSensor] = useState<SensorsAllInformationsViewRow | null>(null);
	const [deviceFamily, setDeviceFamily] = useState<DeviceFamily>(undefined);
	const [alarmStatus, setAlarmStatus] = useState(false);
	const [measurements, setMeasurements] = useState([]);
	const [sensorErrors, setSensorErrors] = useState([]);
	const [sensorCalibrations, setSensorCalibrations] = useState([]);
	const [filteredOccurrences, setFilteredOccurrences] = useState([]);
	const [filteredOtherOccurrences, setFilteredOtherOccurrences] = useState([]);
	const [limitAlarms, setLimitAlarms] = useState([]);
	const [alarmsDetails, setAlarmsDetails] = useState([]);
	const [tiltEnabled, setTiltEnabled] = useState(false);
	const [tiltValues, setTiltValues] = useState([]);
	const [predictiveEnabled, setPredictiveEnabled] = useState(false);
	const [predictiveInfo, setPredictiveInfo] = useState(null);
	const [statistics, setStatistics] = useState(undefined);
	const [events, setEvents] = useState([]);
	const [sensorLimitAlarms, setSensorLimitAlarms] = useState([]);
	const [sensorReplaces, setSensorReplaces] = useState([]);
	const [limitAlarmCount, setLimitAlarmCount] = useState(0);
	const [sensorIssueAlarms, setSensorIssueAlarms] = useState([]);
	const [sensorIssueCount, setSensorIssueCount] = useState(0);
	const [filterActive, setFilterActive] = useState(false);

	const [multiRunInfo, setMultiRunInfo] = useState<RunInfo[]>(undefined);
	const currentRunIdx = multiRunInfo?.findIndex(multiRun => multiRun.SensorId === sensorId);
	const currentRunInfo = multiRunInfo?.at(currentRunIdx);

	const getTimeRangeFromRunInfo = (runInfo: RunInfo) => {
		const endDate = runInfo.EndDate.isValid() ? runInfo.EndDate : dayjs().endOf('day');
		return new TimeRange(runInfo.StartDate, endDate);
	};

	const timeRangePickerAdditionalTimeRange: AdditionalTimeRange = currentRunInfo
		? {
				name: 'Run',
				value: getTimeRangeFromRunInfo(currentRunInfo),
		  }
		: undefined;

	const defaultTimeRange = DateTimeUtils.getXDaysBeforeDay(7);
	const [timeRange, setTimeRange] = useState(defaultTimeRange);
	const dateFromIsoString = timeRange.start.utc().toISOString();
	const dateToIsoString = timeRange.end.utc().toISOString();

	useEffect(() => {
		if (currentRunInfo) {
			setTimeRange(getTimeRangeFromRunInfo(currentRunInfo));
		}
	}, [currentRunIdx]);

	const showMultiRunDropdown = sensor?.multirun && !Authentication.isLiveLink();

	useEffect(() => {
		const getData = async () => {
			setLoading(true);

			const sensorPromise = SensorService.AllInformation(sensorId);
			const measurementsPromise = SensorAnalysisService.measurements(
				`?sensors_id=eq.${sensorId}&tstamp=gte.${dateFromIsoString}&tstamp=lte.${dateToIsoString}&order=tstamp&select=tstamp,value,deferred,bypassed,status,error,calibration`,
				''
			);
			const limitProfilesPromise = SensorAnalysisService.getSensorAlarmsFromRange(sensorId, dateFromIsoString, dateToIsoString, {});
			const occurrencesPromise = SensorAnalysisService.occurrences(
				`?sensors_id=eq.${sensorId}&tstamp=gte.${dateFromIsoString}&tstamp=lte.${dateToIsoString}&order=tstamp&select=tstamp,type_name,prev_state,new_state`,
				''
			);
			const otherOccurrencesPromise = SensorAnalysisService.otherOccurrences(
				`?sensors_id=eq.${sensorId}&tstamp=gte.${dateFromIsoString}&tstamp=lte.${dateToIsoString}&order=tstamp&select=tstamp,type_name,metadata`,
				''
			);
			const eventsPromise = SensorAnalysisService.getEventsForSensor(
				`?sensors_id=eq.${sensorId}&date_occurred=gte.${dateFromIsoString}&date_occurred=lte.${dateToIsoString}&order=id`,
				''
			);

			const sensorResponse = await sensorPromise;
			const measurementsResponse = await measurementsPromise;
			const limitProfilesResponse = await limitProfilesPromise;
			const occurrencesResponse = await occurrencesPromise;
			const otherOccurrencesResponse = await otherOccurrencesPromise;
			const eventsResponse = await eventsPromise;

			const sensor = sensorResponse;
			const measurements = measurementsResponse.data;
			const limitProfiles = limitProfilesResponse.data;
			const occurrences = occurrencesResponse.data;
			const otherOccurrences = otherOccurrencesResponse.data;

			setSensor(sensor);
			const deviceFamily = DeviceUtils.GetDeviceFamily(sensor.serial_number);
			setDeviceFamily(deviceFamily);

			const formatMeasurements = measurements.map(m => {
				const tstampString = moment.parseZone(m.tstamp).utc().toString();
				m.tstamp = DateTimeUtils.utcOffset_date_dep(tstampString).format('YYYY-MM-DDTHH:mm:ss') + '+00:00';
				m.value = m.status ? null : m.value;
				return m;
			});
			setMeasurements(formatMeasurements);

			const formatLimitAlarms = limitProfiles.map(l => {
				const tstampStringFrom = moment.parseZone(l.valid_from).utc().toString();
				l.valid_from = DateTimeUtils.utcOffset_date_dep(tstampStringFrom).format('YYYY-MM-DDTHH:mm:ss') + '+00:00';
				const tstampStringUntil = moment.parseZone(l.valid_until).utc().toString();
				l.valid_until = DateTimeUtils.utcOffset_date_dep(tstampStringUntil).format('YYYY-MM-DDTHH:mm:ss') + '+00:00';
				return l;
			});
			setLimitAlarms(formatLimitAlarms);
			setAlarmsDetails(formatLimitAlarms.length ? formatLimitAlarms[formatLimitAlarms.length - 1].details : []);

			const filteredOccurrences = filterTransitOccurrences(
				occurrences.map(m => {
					const tstampString = moment.parseZone(m.tstamp).utc().toString();
					m.tstamp = DateTimeUtils.utcOffset_date_dep(tstampString).format('YYYY-MM-DDTHH:mm') + '+00:00';
					return m;
				})
			);
			setFilteredOccurrences(filteredOccurrences);

			const filteredOtherOccurrences = filterOtherOccurrences(
				otherOccurrences.map(m => {
					const tstampString = moment.parseZone(m.tstamp).utc().toString();
					m.tstamp = DateTimeUtils.utcOffset_date_dep(tstampString).format('YYYY-MM-DDTHH:mm') + '+00:00';
					return m;
				})
			);
			setFilteredOtherOccurrences(filteredOtherOccurrences);

			const alarmStatus = getAlarmStatus(sensor.latch_active, sensor.latched_status);
			setAlarmStatus(alarmStatus);

			setSensorErrors(getSensorErrors(formatMeasurements));
			setSensorCalibrations(getSensorCalibrations(formatMeasurements));

			const tiltPromise =
				authContext.Organization.Config.global.tiltEnabled && deviceFamily === DeviceFamily.LiberoGx
					? SensorAnalysisService.tiltValues(sensorId.toString(), dateFromIsoString, dateToIsoString, '')
					: Promise.resolve(null);

			const predictivePromise = authContext?.Organization.Config.global.predictiveEnabled
				? PredictionService.GetSensorPredictiveInformation(authContext.Organization.Uuid, sensorId)
				: Promise.resolve(null);

			const multiRunPromise = sensor.multirun ? SensorService.GetMultiUseRunInfo(sensor.id) : Promise.resolve(null);

			const statsPromise = Statistics.getRunStatistics(
				{
					sensors_id: sensorId,
					from: dateFromIsoString,
					to: dateToIsoString,
				},
				''
			);

			const tiltResponse = await tiltPromise;
			const predictiveResponse = await predictivePromise;
			const multiRunResponse = await multiRunPromise;
			const statsResponse = await statsPromise;

			setTiltValues(tiltResponse);
			setTiltEnabled(!!tiltResponse);
			setPredictiveEnabled(!!predictiveResponse);
			setPredictiveInfo(predictiveResponse);
			setMultiRunInfo(multiRunResponse);
			setStatistics(statsResponse.data);

			let events = [...eventsResponse.data].reverse();

			let limitAlarmCount = 0;
			let sensorIssueCount = 0;
			let deviationEvents = events.filter(ev => ev.type === 24);
			let replaceSensorEvents = events.filter(ev => ev.type === 64);
			let sensorLimitAlarms = [];
			let sensorIssueAlarms = [];
			let sensorReplaces = [];

			deviationEvents.forEach(function (ev) {
				ev.details.forEach(function (detail) {
					if (
						detail.type === 5 /*entry reason*/ &&
						(detail.value === SENSOR_LIMIT_ALARMS.UPPER_LIMIT_ALARM[1] ||
							detail.value === SENSOR_LIMIT_ALARMS.LOWER_LIMIT_ALARM[1])
					) {
						sensorLimitAlarms.push({tstamp: ev.date_occurred, desc: detail.value});
						limitAlarmCount++;
					} else if (
						detail.type === 5 /*entry reason*/ &&
						(detail.value === SENSOR_ISSUE_ALARMS.RADIO_CONNECTION_WARNING[1] ||
							detail.value === SENSOR_ISSUE_ALARMS.LOW_BATTERY_WARNING[1] ||
							detail.value === SENSOR_ISSUE_ALARMS.LOST_MEASUREMENT_ALARM[1] ||
							detail.value === SENSOR_ISSUE_ALARMS.MISSING_COMMUNICATION_WARNING[1] ||
							detail.value === SENSOR_ISSUE_ALARMS.MISSING_VALUE_ALARM[1] ||
							detail.value === SENSOR_ISSUE_ALARMS.SENSOR_FAILURE_ALARM[1] ||
							detail.value === SENSOR_ISSUE_ALARMS.TILT_WARNING[1])
					) {
						sensorIssueAlarms.push({tstamp: ev.date_occurred, desc: detail.value});
						sensorIssueCount++;
					}
				});
			});

			replaceSensorEvents.forEach(function (ev) {
				sensorReplaces.push({tstamp: ev.date_occurred, desc: REPLACE_SENSOR_EVENTS.REPLACE_SENSOR[0]});
			});

			setEvents(events);
			setSensorLimitAlarms(sensorLimitAlarms);
			setSensorReplaces(sensorReplaces);
			setLimitAlarmCount(limitAlarmCount);
			setSensorIssueAlarms(sensorIssueAlarms);
			setSensorIssueCount(sensorIssueCount);

			setLoading(false);
		};
		if (sensorId) {
			getData().catch(e => console.error(e));
		}
	}, [sensorId, dateFromIsoString, dateToIsoString]);

	if (!sensor) return <LoadingComponent />;

	const close = (e: MouseEvent) => {
		const DevicesViewSettings = accessContext.user.user_settings?.devicesViewSettings ?? {view: '/dashboard'};
		navigate(DevicesViewSettings.view);
		e.preventDefault();
	};

	const handleNewMultiRun = (multiRunInfo: RunInfo) => {
		navigate(`${Paths.Sensor.Analysis}/${multiRunInfo.SensorId}`);
	};

	function filterTransitOccurrences(occurrences) {
		return occurrences.filter(
			e =>
				(OCCURRENCES.REQUIRED_TYPES[0] === e.type_name &&
					LOGGER_STATUS_EVENTS.REQUIRED_STATUSES.some(element => element === e.new_state)) ||
				e.type_name === OCCURRENCES.REQUIRED_TYPES[1]
		);
	}

	function filterOtherOccurrences(otherOccurrences) {
		return otherOccurrences.filter(e => OTHER_OCCURRENCES.REQUIRED_TYPES.some(element => element === e.type_name));
	}

	function getAlarmStatus(latchActive, latchedStatus) {
		return !!(latchActive && latchedStatus);
	}

	function getSensorErrors(measurements) {
		const sensorErrors = [];

		measurements.forEach((measurement, idx) => {
			if (measurement.status && (!measurements[idx - 1] || measurements[idx - 1].error !== measurement.error)) {
				const endPoint = getErrorRangeEndPoint(measurements, idx);

				sensorErrors.push({
					start: moment.parseZone(idx < 1 ? measurement.tstamp : measurements[idx - 1].tstamp).utc(),
					end: moment.parseZone(measurements[endPoint].tstamp).utc(),
					status: measurement.status,
					type: measurement.error,
				});
			}
		});

		return sensorErrors;
	}

	function getErrorRangeEndPoint(measurements, startPoint) {
		const endPoint = measurements.findIndex(
			(measurement, idx) => idx > startPoint && measurements[startPoint].error !== measurement.error
		);
		return endPoint >= 0 ? endPoint : measurements.length - 1;
	}

	function getSensorCalibrations(measurements) {
		const calibrationRuns = [];

		measurements.forEach((measurement, idx) => {
			if (measurement.calibration && !measurements[idx - 1]?.calibration) {
				const endPoint = getCalibrationRunEndPoint(measurements, idx);

				calibrationRuns.push({
					start: moment.parseZone(measurement.tstamp).utc(),
					end: moment.parseZone(measurements[endPoint].tstamp).utc(),
				});
			}
		});

		return calibrationRuns;
	}

	function getCalibrationRunEndPoint(measurements, startPoint) {
		const endPoint = measurements.findIndex((measurement, idx) => idx > startPoint && !measurement.calibration);
		return endPoint >= 0 ? endPoint : measurements.length - 1;
	}

	const report = async e => {
		setReportLoading(true);
		const dateFrom = timeRange.start.utc().toISOString();
		const dateTo = timeRange.end.utc().toISOString();

		const chartSVG = chart.current?.getChart().getChartHTML();
		const tiltSVG = tiltComponent.current?.getChart().getChartHTML();
		const mapSVG = mapComponent.current?.getMap();

		let currMRStxt = '';
		if (sensor.multirun) {
			currMRStxt =
				currentRunIdx +
				'.Run, ' +
				formatDayJsInUserTz(currentRunInfo.StartDate) +
				(currentRunInfo.EndDate ? ' - ' + formatDayJsInUserTz(currentRunInfo.EndDate) : ' - ');
		}

		let payload = {
			sensor: {
				id: sensorId,
				name: sensor.name,
				serial_number: sensor.serial_number,
				alarmStatus: alarmStatus,
			},
			timeFilter: {
				from: dateFrom,
				to: dateTo,
			},
			svgImage: chartSVG,
			svgMapImage: mapSVG,
			runInfo: currMRStxt,
			...(authContext.Organization.Config.global.tiltEnabled && {svgTiltImage: tiltSVG}),
		};
		try {
			await SensorAnalysisService.sensorAnalysisReport(payload, '');
		} catch (error) {
			console.error(error);
		} finally {
			setReportLoading(false);
		}
	};

	const exportData = async (exportType: string, withGeoData) => {
		setReportLoading(true);

		const payload = {
			SensorId: sensorId,
			FileSuffix: parseInt(exportType),
			TimeFilter: {
				FromUtc: timeRange.start.utc().toISOString(),
				ToUtc: timeRange.end.utc().toISOString(),
			},
		};

		try {
			await SensorAnalysisService.exportData(payload, '');
		} catch (error) {
			console.error(error);
		} finally {
			setReportLoading(false);
		}

		if (withGeoData) {
			setReportLoading(true);
			try {
				await SensorAnalysisService.exportGeoData(payload, '');
			} catch (error) {
				console.error(error);
			} finally {
				setReportLoading(false);
			}
		}
	};

	const handleChartSelection = event => {
		if (event.trigger === 'zoom' && event.userMin) {
			const zoomRange = new TimeRange(dayjs(event.userMin).startOf('minute'), dayjs(event.userMax).startOf('minute'));
			setTimeRange(zoomRange);
			setSearchParams({
				...searchParams,
				[searchParamStart]: zoomRange.start.unix().toString(),
				[searchParamEnd]: zoomRange.end.unix().toString(),
			});
			setFilterActive(true);
		}
	};

	const resetZoom = () => {
		const zoomRange = sensor?.multirun && currentRunInfo ? getTimeRangeFromRunInfo(currentRunInfo) : defaultTimeRange;
		setTimeRange(zoomRange);
		setSearchParams({
			...searchParams,
			[searchParamStart]: zoomRange.start.unix().toString(),
			[searchParamEnd]: zoomRange.end.unix().toString(),
		});
		setFilterActive(false);
	};

	return (
		<ViewWrapper>
			<ViewHeader heading={sensor.name}>
				<Access
					access={accessPermissions.devicesview.child.dashboard.child.sensorAnalysis.child.selectPreviousSensorRuns}
					roles={userRoles.default}
				>
					<div style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
						<ReportComponent
							debugReport={false}
							reportLocalhost={() => {}}
							loadingReport={reportLoading}
							report={report}
							exportFunction={exportData}
							exportShowGeo={sensor.has_map}
							sensorId={sensorId}
						/>
						{showCloseButton && <CloseButton onClick={close} />}
					</div>
				</Access>
			</ViewHeader>
			<ContentWrapper>
				{sensor.multirun && (
					<Card title={t('titles.run').toString()}>
						<Flex justify={'space-between'}>
							<Flex vertical={true}>
								{showMultiRunDropdown && (
									<MultiRunDropdown runInfos={multiRunInfo} selectedRunId={sensorId} onSelect={handleNewMultiRun} />
								)}
							</Flex>
							<AlarmStateComponent isAlarmed={alarmStatus} />
						</Flex>
					</Card>
				)}
				<Card title="Sensor Analysis Period">
					<TimeRangePicker
						onTimeRangeChanged={setTimeRange}
						value={timeRange}
						additionalTimeRanges={[timeRangePickerAdditionalTimeRange]}
					/>
				</Card>

				<Spin spinning={loading}>
					<Card title={t('titles.chart').toString()}>
						<ChartComponent
							forwardRef={chart}
							sensor={sensor}
							moduleFamilyType={deviceFamily}
							measurements={measurements}
							sensorErrors={sensorErrors}
							sensorCalibrations={sensorCalibrations}
							occurrences={filteredOccurrences}
							otherOccurrences={filteredOtherOccurrences}
							limitAlarms={limitAlarms}
							sensorLimitAlarms={sensorLimitAlarms}
							sensorIssueAlarms={sensorIssueAlarms}
							sensorReplaces={sensorReplaces}
							dateFrom={timeRange.start}
							dateTo={timeRange.end}
							startRun={null} // run start
							stopRun={null} //run stop
							deviationStart={null}
							deviationEnd={null}
							zoomType="x"
							handleChartSelection={handleChartSelection}
							resetZoom={resetZoom}
							filterActive={filterActive}
							outUnitsId={statistics?.outUnitsId}
						/>
					</Card>
					{tiltEnabled && (
						<Card title={t('titles.tiltValues').toString()}>
							<TiltGraph
								tiltProfile={sensor.metadata.tilt_detection}
								tiltDeviations={events
									.filter(
										e =>
											e.details.some(d => d.type === 5 && d.value === SENSOR_ISSUE_ALARMS.TILT_WARNING[1]) &&
											e.details.some(d => d.type === 145 && d.value !== undefined)
									)
									.map(e => ({
										timestamp: dayjs(e.date_occurred),
										tiltAngle: Number(e.details.find(d => d.type === 145).value),
									}))}
								tiltValues={tiltValues}
								filterActive={filterActive}
								handleChartSelection={handleChartSelection}
								resetZoom={resetZoom}
								forwardRef={tiltComponent}
							/>
						</Card>
					)}

					{predictiveEnabled && (
						<Card title={t(PredictiveTrans.title).toString()}>
							<PredictionResultView sensorPredictiveInfo={predictiveInfo} sensor={new Sensor(sensor)} />
						</Card>
					)}

					{sensor.metadata && (
						<Card title="Metadata">
							<SensorMetaData sensor={sensor} />
						</Card>
					)}

					{sensor.has_map && (
						<Card title="Map">
							<MapComponent
								ref={mapComponent}
								sensor={sensor}
								dateFrom={timeRange.start.toISOString()}
								dateTo={timeRange.end.toISOString()}
							/>
						</Card>
					)}

					{
						//these could maybe be disabled? TODO
						statistics?.outUnitsId && (
							<Card title="Statistics">
								<SensorAnalysisStatistics
									sensor={sensor}
									statistics={statistics}
									limitAlarmDetails={alarmsDetails}
									limitAlarmCount={limitAlarmCount}
									sensorIssueCount={sensorIssueCount}
									outUnitsId={statistics.outUnitsId}
								/>
							</Card>
						)
					}

					<Card title={t('titles.events').toString()}>
						<EventTable events={events} />
					</Card>
				</Spin>
			</ContentWrapper>
		</ViewWrapper>
	);
}

export default SensorAnalysis;
