import React from 'react';
import {Row, Col} from 'reactstrap';
import {MODES} from '../Modes';
import CheckBoxComponent from './CheckBoxComponent';
import {TILT_WARNING_INITIAL_POSITIONS} from '../../../../Shared/Constants/TiltWarning/TiltWarning';

interface TiltWarningProps {
	handleIssueAlarmInputChange: (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
	formSettings: {
		tiltWarningCheckboxDisabled: boolean;
	};
	issueProfile: {
		tilt_warning: boolean;
		tilt_warning_initial_position: {reference_x: number; reference_y: number; reference_z: number};
		tilt_warning_angle: number;
	};
	mode: string;
}

export default function TiltWarningComponent(props: Readonly<TiltWarningProps>) {
	const selectedPosition = Object.values(TILT_WARNING_INITIAL_POSITIONS).find(
		v =>
			v.reference_x === props.issueProfile.tilt_warning_initial_position?.reference_x &&
			v.reference_y === props.issueProfile.tilt_warning_initial_position?.reference_y &&
			v.reference_z === props.issueProfile.tilt_warning_initial_position?.reference_z
	);

	return (
		<>
			<CheckBoxComponent
				id="checkboxTiltWarning"
				name="tilt_warning"
				text="Tilt warning"
				onChange={props.handleIssueAlarmInputChange}
				checked={props.issueProfile.tilt_warning}
				disabled={props.formSettings.tiltWarningCheckboxDisabled}
			/>
			<br />
			<Row>
				<Col xl={3}>
					<select
						className="form-control"
						id="tiltWarningInitialPositionId"
						name="tilt_warning_initial_position_id"
						value={selectedPosition?.id}
						onChange={props.handleIssueAlarmInputChange}
						disabled={props.mode === MODES.DISPLAY ? true : !props.issueProfile.tilt_warning}
					>
						{Object.values(TILT_WARNING_INITIAL_POSITIONS).map(v => (
							<option key={v.id} value={v.id}>
								{v.name}
							</option>
						))}
					</select>
				</Col>
			</Row>
			<br />
			<Row>
				<Col xl={3}>
					<select
						className="form-control"
						id="tiltWarningAngle"
						name="tilt_warning_angle"
						value={props.issueProfile.tilt_warning_angle}
						onChange={props.handleIssueAlarmInputChange}
						disabled={props.mode === MODES.DISPLAY ? true : !props.issueProfile.tilt_warning}
					>
						{Array.from({length: 12}, (_, i) => (i + 1) * 15).map(angle => (
							<option key={angle} value={angle}>
								{angle}°
							</option>
						))}
					</select>
				</Col>
			</Row>
		</>
	);
}
