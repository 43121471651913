export const COLUMNS = {
	MENU: 'Menu',
	ISSUE_PROFILE_NAME: 'Name',
	SENSOR_FAILURE_ALARM: 'SensorFailure',
	LOW_BATTERY_WARNING: 'LowBattery',
	LOST_MEASUREMENT_ALARM: 'LostMeasurementAlarm',
	MISSING_COMMUNICATION_WARNING: 'MissingCommunication',
	LIGHT_WARNING: 'LightDetector',
	TILT_WARNING: 'TiltWarning',
	IN_USE_BY: 'InUseBy',
	LOCKED: 'Locked',
};
