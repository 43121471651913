import Authentication from '../../Infrastructure/Authentication/Authentication';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import duration, {DurationUnitType} from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import moment from 'moment-timezone';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import locales from 'dayjs/locale.json';
import {TimeRange} from './TimeRange';

dayjs.extend(LocalizedFormat);
dayjs.extend(duration);
dayjs.extend(isoWeek);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

loadDayJsLocale();

function loadDayJsLocale(): void {
	const localStorageKey = 'dayJsLocale';
	const languages = navigator.languages || (navigator.language ? [navigator.language] : []);
	if (!languages?.length) return;

	// load locale
	function loadLocale(locale) {
		if (!locale) return false;
		import(`dayjs/locale/${locale}.js`).then(_ => {
			localStorage.setItem(localStorageKey, locale);
			dayjs.locale(locale);
		});
		return true;
	}

	if (loadLocale(localStorage.getItem(localStorageKey))) return;

	const dayJsLocales = locales.map(l => l.key);

	function checkLocale(locale) {
		if (['en', 'en-us'].includes(locale)) return true;
		if (locale === 'zn') return loadLocale('zh-cn');
		if (locale === 'no') return loadLocale('nb');
		if (dayJsLocales.includes(locale)) return loadLocale(locale);
	}

	languages.some(language => {
		language = language.toLowerCase();
		return checkLocale(language) || (language.includes('-') && checkLocale(language.split('-')[0]));
	});
}

function getUserToday(): dayjs.Dayjs {
	const offset = DateTimeUtils.getCurrentUserTZOffset();
	return dayjs().utcOffset(offset).startOf('day');
}

export function formatDayJsInUserTz(dateTime: dayjs.Dayjs): string {
	if (!dateTime?.isValid()) {
		return undefined;
	}
	return dateTime.utcOffset(DateTimeUtils.getCurrentUserTZOffset()).format('DD. MMM YYYY HH:mm');
}

export const DateTimeUtils = {
	/**
	 * Check, if the date is in the left closed, right open interval
	 * @param date date to check for
	 * @param timeRange left closed, right open interval
	 */
	isInTimeRange(date: dayjs.Dayjs, timeRange: TimeRange) {
		const afterStartClosedInterval = timeRange?.start ? timeRange.start <= date : true;
		const beforeEndOpenInterval = timeRange?.end ? date < timeRange.end : true;

		return afterStartClosedInterval && beforeEndOpenInterval;
	},

	formatSecondsAsDuration(seconds: number): string {
		const duration = dayjs.duration(seconds, 's');
		return duration.toISOString();
	},

	/**
	 * Formats seconds as a human-readable string. Will use ISO notation for durations >1d (this was not a requirement,
	 * mainly laziness).
	 * @param seconds
	 */
	formatSecondsAsHumanDuration(seconds: number): string {
		seconds = Math.abs(seconds);
		const duration = dayjs.duration(seconds, 's');

		if (duration.asDays() >= 1) {
			return this.formatSecondsAsDuration(seconds);
		}

		let formatString = 's';
		let suffix = 's';
		if (duration.asMinutes() >= 1) {
			formatString = 'm:s' + formatString;
			suffix = 'min';
		}

		if (duration.asHours() >= 1) {
			formatString = 'H:m' + formatString;
			suffix = 'h';
		}

		return duration.format(`${formatString} [${suffix}]`);
	},

	/**
	 * Convert a given date to an open interval depending on the unit. So the beginning of the next day, if unit is day.
	 *
	 * @param date The date to be converted, needs to be in the correct time zone.
	 * @param unit dayjs unit, ms, s, hour, ect.
	 */
	getOpenInterval(date: dayjs.Dayjs, unit: dayjs.ManipulateType): dayjs.Dayjs {
		return date.startOf(unit).add(1, unit);
	},

	/**
	 * Get x days including date, counting backwards as a left closed, right open interval
	 * @param x the amount of days
	 * @param date the end date in a right closed interval
	 * @param includeTime
	 */
	getXDaysBeforeDay(x: number, date: dayjs.Dayjs = getUserToday(), includeTime: boolean = false): TimeRange {
		const offset = DateTimeUtils.getCurrentUserTZOffset();
		let end = dayjs().utcOffset(offset);
		if (!includeTime) {
			end = DateTimeUtils.getOpenInterval(date, 'day');
		}

		return new TimeRange(end.subtract(x, 'days'), end);
	},

	/**
	 * Get a left closed, right open time range starting at Unix 0, including today
	 */
	getAllTimeRange(): TimeRange {
		return new TimeRange(dayjs(0), DateTimeUtils.getOpenInterval(getUserToday(), 'day'));
	},

	dayjsToHighchartsTimestamp(timestamp: dayjs.Dayjs): number {
		return timestamp.unix() * 1000;
	},

	stringToHighchartsTimestamp(timestamp: string): number {
		return dayjs(timestamp).unix() * 1000;
	},

	/**
	 * Print date time in user format
	 * @param date
	 */
	toUserString(date: dayjs.Dayjs) {
		return date.utcOffset(this.getCurrentUserTZOffset()).format('DD. MMM YYYY HH:mm');
		// Should really use this, but needs some testing (and a merge request for en_DK)
		// return date.utcOffset(this.getCurrentUserTZOffset()).format('lll');
	},

	getCurrentUserTZOffset(): number {
		const localStorageTz = Authentication.get_tzOffset(); // localStorage.getItem('tzOffset');
		return localStorageTz ? parseInt(localStorageTz) : dayjs().utcOffset();
	},

	getUsersToday(): Date {
		const offset = this.getCurrentUserTZOffset();
		const today = moment().utc().utcOffset(offset);
		return today.toDate();
	},

	/**
	 * @deprecated implement a new function that returns a left closed interval with a new datatype {start, end}
	 * @param today
	 */
	getLastYear(today?: Date) {
		if (today === undefined) {
			//today = new Date();
			today = this.getUsersToday();
		}

		const firstDayLastYear = new Date(today.getFullYear() - 1, 0, 1, 0, 0, 0);
		const lastDayLastYear = new Date(today.getFullYear(), 0, 1, 0, 0, 0);
		return {
			firstDayLastYear: firstDayLastYear,
			lastDayLastYear: lastDayLastYear,
		};
	},

	/**
	 * @deprecated implement a new function that returns a left closed interval with a new datatype {start, end}
	 * @param today
	 */
	getLastMonth(today?: Date) {
		if (today === undefined) {
			//today = new Date();
			today = this.getUsersToday();
		}

		const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0);
		const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0);
		return {
			firstDayLastMonth: firstDayLastMonth,
			lastDayLastMonth: lastDayLastMonth,
		};
	},

	/**
	 * @deprecated implement a new function that returns a left closed interval with a new datatype {start, end}
	 * @param today
	 */
	getLastWeek(today?: Date) {
		if (today === undefined) {
			//today = new Date();
			today = this.getUsersToday();
		}

		const lastDayLastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + 1, 0, 0, 0);
		const firstDayLastWeek = new Date(
			lastDayLastWeek.getFullYear(),
			lastDayLastWeek.getMonth(),
			lastDayLastWeek.getDate() - 7,
			0,
			0,
			0
		);

		return {
			firstDayLastWeek: firstDayLastWeek,
			lastDayLastWeek: lastDayLastWeek,
		};
	},

	/**
	 * @deprecated use getXDaysBeforeDay instead
	 * @param today
	 */
	getLast7Days(today?: Date) {
		if (today === undefined) {
			//today = new Date();
			today = this.getUsersToday();
		}

		const firstDayLast7Days = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - 7, 0, 0, 0);
		const lastDayLast7Days = new Date(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() + 1, 0, 0, 0);

		return {
			firstDayLast7Days: firstDayLast7Days,
			lastDayLast7Days: lastDayLast7Days,
		};
	},

	/**
	 * Calculates the time range (start and end) for a given date, duration unit, and delta value.
	 *
	 * @param {dayjs.Dayjs} date - The starting date from which the time period is calculated.
	 * @param {DurationUnitType | "isoWeek"} durationUnit - The unit of duration to calculate the time period. Use 'isoWeek' for ISO week calculations.
	 * @param {number} delta - The offset in the specified duration unit to calculate the new time period.
	 * @return {TimeRange} An object containing the calculated start and end points of the time period.
	 */
	getTimePeriod(date: dayjs.Dayjs, durationUnit: DurationUnitType | 'isoWeek', delta: number): TimeRange {
		const deltaUnit = durationUnit == 'isoWeek' ? 'week' : durationUnit;
		const newDate = date.add(delta, deltaUnit);
		const newDateFrom = newDate.startOf(durationUnit);
		const newDateTo = newDate.add(1, deltaUnit).startOf(durationUnit);

		return new TimeRange(newDateFrom, newDateTo);
	},

	getDateTimeInUserTZ(dateTime: any): string | undefined {
		if (dateTime === undefined) {
			return;
		}

		// const offset = parseInt(localStorage.getItem('tzOffset'));
		// const tstampString = moment(date).utc().toString();
		let offset = this.getOffsetForDate(dateTime);
		return moment.utc(dateTime).utcOffset(offset).format('DD. MMM YYYY HH:mm');
	},

	getUTCDateTimeFormat(dateTime: moment.MomentInput): string {
		return moment.utc(dateTime).format('DD. MMM YYYY HH:mm');
	},

	supports_dst(): boolean {
		return Authentication.get_supports_dst() === 'true';
		//return localStorage.getItem('supports_dst') === 'true';
	},

	base_utc_offset(): number {
		return parseInt(Authentication.get_base_utc_offset());
		//return parseInt(localStorage.getItem('base_utc_offset'));
	},

	timezone(): string {
		return Authentication.get_timezone();
		//return localStorage.getItem('timezone');
	},

	getIsoFromUCTWithoutOffset(date: moment.MomentInput, offset: moment.DurationInputArg1): string {
		const utcOffsetSubtract = moment(date).subtract(offset, 'minutes').format('YYYY-MM-DDTHH:mm:ss');
		const isoString = moment(utcOffsetSubtract).toISOString(true);
		let isoStringRemoveOffset = isoString.substring(0, isoString.indexOf('+'));
		if (isoStringRemoveOffset === '') {
			isoStringRemoveOffset = isoString.substring(0, isoString.lastIndexOf('-'));
		}

		return isoStringRemoveOffset.substring(0, isoStringRemoveOffset.lastIndexOf(':')) + ':00Z';
	},

	getOffsetForDate(tstampString: string) {
		let offset = this.getCurrentUserTZOffset(); // this.base_utc_offset();
		/*
		if (this.supports_dst()) {
			const tz = this.timezone(); //'Europe/Berlin';//this.timezone();
			// https://stackoverflow.com/questions/23997575/why-does-momentjs-isdst-return-wrong-time-when-zone-is-used

			if (moment.tz(tstampString, tz).isDST() === true) {
				offset += 60;
			}
		}
		*/
		return offset;
	},

	setDateTimeWithOffset_date_dep(date: moment.MomentInput): string {
		const tstampString = moment.parseZone(date).utc().toString();
		let offset = this.getOffsetForDate(tstampString);
		return moment(tstampString).utcOffset(offset).format('DD. MMM YYYY HH:mm');
	},

	setDateTimeWithOffset_date_dep_date(date: moment.MomentInput): string {
		const tstampString = moment.parseZone(date).utc().toISOString();
		let offset = this.getOffsetForDate(tstampString);
		return moment(tstampString).utcOffset(offset).format('DD. MMM YYYY');
	},

	setDateTimeWithOffset_date_dep_time(date: moment.MomentInput): string {
		const tstampString = moment.parseZone(date).utc().toISOString();
		let offset = this.getOffsetForDate(tstampString);
		return moment(tstampString).utcOffset(offset).format('HH:mm');
	},

	addUserUTCOffsetToDateTime_date_dep(date: moment.MomentInput): string {
		const tstampString = moment.parseZone(date).utc().toString();
		let offset = this.getOffsetForDate(tstampString);
		return moment(tstampString).utcOffset(offset).format('YYYY-MM-DDTHH:mm:ss') + '+00:00';
	},

	utcOffset_date_dep(date: moment.MomentInput): moment.Moment {
		const tstampString = moment.parseZone(date).utc().toString();
		let offset = this.getOffsetForDate(tstampString);
		return moment(tstampString).utcOffset(offset);
	},

	getLocalDateFromUnixTimestamp(timestamp: number): Date {
		return new Date(timestamp * 1000);
	},

	getUTCFromUnixTime_date_dep(unixTime: number): string {
		const tstampString = moment.unix(unixTime).utc().toString();
		let offset = this.getOffsetForDate(tstampString);
		return moment(tstampString).utc(false).utcOffset(offset).format('DD. YYYY HH:mm');
	},

	getUTCFromUnixTime_date_dep_date(unixTime: number): string {
		const tstampString = moment.unix(unixTime).utc().toString();
		let offset = this.getOffsetForDate(tstampString);
		return moment(tstampString).utc(false).utcOffset(offset).format('DD. MMM YYYY');
	},

	getUTCFromUnixTime_date_dep_time(unixTime: number): string {
		const tstampString = moment.unix(unixTime).utc().toString();
		let offset = this.getOffsetForDate(tstampString);
		return moment(tstampString).utc(false).utcOffset(offset).format('HH:mm');
	},

	formatDurationTime(durationRaw?: string) {
		if (!durationRaw) {
			return;
		}
		const duration = durationRaw.split('.');
		return duration.length > 1 ? duration[0] + ' d ' + duration[1] + ' h' : duration[0] + ' h';
	},

	/**
	 * Checks if two dateTimes have the same date (year, month, day) in localtime
	 * @param left
	 * @param right
	 */
	dateTimeAreEqualDates(left: Date, right: Date): boolean {
		return left.getFullYear() === right.getFullYear() && left.getMonth() === right.getMonth() && left.getDate() === right.getDate();
	},

	//it is not restricted to datetime value, its more a general function for array
	//but used in that context, can be rearranged to a library if needed
	lowerBound(array, value, compare, lo, hi) {
		const lowerBound_cmp = (array, value, compare, lo, hi) => {
			lo = lo | 0;
			hi = hi | 0;
			while (lo < hi) {
				const m = (lo + hi) >>> 1,
					v = compare(value, array[m]);
				if (v < 0) {
					hi = m - 1;
				} else if (v > 0) {
					lo = m + 1;
				} else {
					hi = m;
				}
			}
			if (compare(array[lo], value) <= 0) {
				return lo;
			}
			return lo - 1;
		};

		const lowerBound_def = (array, value, lo, hi) => {
			lo = lo | 0;
			hi = hi | 0;
			while (lo < hi) {
				const m = (lo + hi) >>> 1;
				if (value < array[m]) {
					hi = m - 1;
				} else if (value > array[m]) {
					lo = m + 1;
				} else {
					hi = m;
				}
			}
			if (array[lo] <= value) {
				return lo;
			}
			return lo - 1;
		};

		if (!lo) {
			lo = 0;
		}
		if (typeof hi !== 'number') {
			hi = array.length - 1;
		}
		if (compare) {
			return lowerBound_cmp(array, value, compare, lo, hi);
		}
		return lowerBound_def(array, value, lo, hi);
	},
};
export default DateTimeUtils;
