import React, {useContext, useEffect, useState} from 'react';
import {Card} from '../Common';
import {Access} from '../Infrastructure/Authorization/Components';
import {AccessPermissions, UserRoles} from '../Infrastructure/Authorization/Access';
import {Button, Checkbox, Col, Form, Input, Row} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {formProps} from '../../common/props/AntFormProps';
import * as uuid from 'uuid';
import {IUpdateOrganizationByIdRequest as PredictiveOrganization} from '@elproag/predict';
import {AuthContext} from '../../common/context';
import {PredictionService} from '../../common/services';
import {ErrorAlert, Success} from '../Common/Alerts';
import {nameof} from '../../common/util';

const uuidPlaceholder = uuid.v4();

export function PredictiveSettingsForm() {
	const [t] = useTranslation();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(true);
	const [editing, setEditing] = useState(false);
	const [valuesHaveChanged, setValuesHaveChanged] = useState(false);
	const [savingInProgress, setSavingInProgress] = useState(false);

	const authContext = useContext(AuthContext);

	const orgUuid = authContext.Organization?.Uuid;

	const [organisation, setOrganisation] = useState<PredictiveOrganization>({
		includeGenericContainers: true,
		includeGenericLanes: true,
	});

	useEffect(() => {
		setLoading(true);
		if (!orgUuid) return;
		PredictionService.GetOrganizationById(orgUuid)
			.then(r => {
				if (r.organization) setOrganisation(r.organization);
				setLoading(false);
			})
			.catch();
	}, [orgUuid]);

	const translate = (key: string): string => t(`settings.predictive.${key}`).toString();

	function getButtons(): React.JSX.Element {
		return (
			<Access
				access={AccessPermissions.settings.child.organization.child.editOrganization}
				roles={[...UserRoles.admin, ...UserRoles.operator]}
			>
				{editing ? (
					<Row gutter={8}>
						<Col>
							<Button
								type={'primary'}
								htmlType={'submit'}
								loading={savingInProgress}
								disabled={!valuesHaveChanged}
								size={'large'}
							>
								{t('global.save').toString()}
							</Button>
						</Col>
						<Col>
							<Button onClick={onCancelClicked} size={'large'}>
								{t('global.cancel').toString()}
							</Button>
						</Col>
					</Row>
				) : (
					<Col>
						<Button type={'primary'} icon={<EditOutlined />} size={'large'} onClick={() => setEditing(true)} disabled={false}>
							{t('global.edit').toString()}
						</Button>
					</Col>
				)}
			</Access>
		);
	}

	function validateUuid(_, value: string): Promise<void> {
		if (value == null || uuid.validate(value)) {
			return Promise.resolve();
		}
		return Promise.reject(new Error(translate('invalidId')));
	}

	function updateValuesHaveChanged(_changedValues: any, values: Partial<PredictiveOrganization>) {
		const changed = Object.keys(values)
			.map(k => values[k] === organisation[k])
			.some(v => v === false);

		setValuesHaveChanged(changed);
	}

	function onFormFinish(organisation: PredictiveOrganization) {
		setSavingInProgress(true);

		PredictionService.UpdateOrganization(orgUuid, organisation)
			.then(() => {
				setEditing(false);
				setValuesHaveChanged(false);
				setOrganisation(organisation);
				Success.fire({text: translate('updateSuccess')}).then();
			})
			.catch(e => {
				console.error(e);
				ErrorAlert.fire({text: translate('updateError')}).then();
			});
		setSavingInProgress(false);
	}

	function onCancelClicked() {
		setEditing(false);
	}

	return loading ? (
		<></>
	) : (
		<Form
			{...formProps}
			disabled={!editing}
			requiredMark={false}
			initialValues={organisation}
			onFinish={onFormFinish}
			form={form}
			onValuesChange={updateValuesHaveChanged}
		>
			<Card title={translate('smartCaeSettings')} extra={getButtons()}>
				<Form.Item
					label={translate('id')}
					name={nameof<PredictiveOrganization>('smartCaeCustomerId')}
					rules={[{validator: validateUuid}]}
					getValueFromEvent={e => (e.target.value == '' ? null : e.target.value)}
				>
					<Input placeholder={`Example: ${uuidPlaceholder}`} />
				</Form.Item>
				<Form.Item
					label={translate('includeGenericBoxes')}
					name={nameof<PredictiveOrganization>('includeGenericContainers')}
					valuePropName="checked"
				>
					<Checkbox />
				</Form.Item>
				<Form.Item
					label={translate('includeGenericLanes')}
					name={nameof<PredictiveOrganization>('includeGenericLanes')}
					valuePropName="checked"
				>
					<Checkbox />
				</Form.Item>
			</Card>
		</Form>
	);
}
