import React from 'react';
import {Select} from 'antd';
import {
	MISSING_COMMUNICATION_WARNING_ENUM,
	MISSING_COMMUNICATION_WARNING_STRINGS,
} from '../../../../Shared/Constants/MissingCommunicationWarning/MissingCommunicationWarning';
const {Option} = Select;

type MissingCommunicationWarningProps = {
	value: number | undefined;
	disabled: boolean;
};

export default function MissingCommunicationWarningSelectComponent(props: MissingCommunicationWarningProps) {
	return (
		<Select id="selectMissingCommunicationWarning" style={{width: '100%'}} value={props.value} disabled={props.disabled}>
			{Object.values(MISSING_COMMUNICATION_WARNING_ENUM)
				.filter(v => !isNaN(Number(v)))
				.map(v => (
					<Option key={v} value={v}>
						{MISSING_COMMUNICATION_WARNING_STRINGS[v]}
					</Option>
				))}
		</Select>
	);
}
