import React, {useState} from 'react';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from 'react-accessible-accordion';

const ChartLegend = props => {
	const [count, setCount] = useState(0);

	if (props.setUpdateFun !== undefined && props.setUpdateFun !== null) {
		props.setUpdateFun(() => {
			setCount(count + 1); // make a rerender consequential
		});
	}
	const chart = props.chartComponent;

	return (
		<Accordion>
			<AccordionItem id="chartSettings" expanded>
				<AccordionItemTitle id="chartSettingsTitle">
					<h5 className="u-position-relative">
						Chart settings
						<div className="accordion__arrow" role="presentation" />
					</h5>
				</AccordionItemTitle>
				<AccordionItemBody>
					{chart?.deferredDataPoints?.length > 0 && (
						<Checkbox
							id="reloaded-values-check-box"
							data-test="reloadedValues"
							checked={chart.state ? chart.state.showReloadedValues : false}
							onClick={chart.onShowReloadedValuesClick}
						>
							Show Reloaded Values
						</Checkbox>
					)}
					{chart?.sensorCalibrations?.length > 0 && (
						<Checkbox
							id="sensor-calibrations-check-box"
							data-test="calibrationValues"
							checked={chart.state ? chart.state.showSensorCalibrationValues : false}
							onClick={chart.onShowSensorCalibrationsClick}
						>
							Calibration
						</Checkbox>
					)}
					{chart?.communication_times?.length > 0 && (
						<Checkbox
							id="communication-times"
							data-test="communication_times"
							checked={chart.state ? chart.state.showMeasurementUploads : false}
							onClick={chart.onShowMeasurementUploadsClick}
						>
							Communication times
						</Checkbox>
					)}
					{chart?.bypassedDataPoints?.length > 0 && (
						<Checkbox
							id="alarming-inactive-check-box"
							data-test="bypassedDataPoints"
							checked={chart.state ? chart.state.showBypassedValues : false}
							onClick={chart.onShowBypassedValuesClick}
						>
							Alarming inactive
						</Checkbox>
					)}
					{chart?.state?.startRunStopRunSettings?.showStartRunStopRunCheckBox && (
						<Checkbox
							id="start-stop-run-check-box"
							data-test="showStartRunStopRunValues"
							checked={chart.state.startRunStopRunSettings.isEnabled}
							onClick={chart.onShowStartRunStopRunClick}
						>
							Start/Stop Run
						</Checkbox>
					)}
					{chart?.state?.alarmingOnAlarmingOffSettings?.showAlarmingOnAlarmingOffCheckBox && (
						<Checkbox
							id="alarming-on-off-check-box"
							data-test="showAlarmingOnAlarmingOffValues"
							checked={chart.state.alarmingOnAlarmingOffSettings.isEnabled}
							onClick={chart.onShowAlarmingOnAlarmingOffRunClick}
						>
							Alarming On/Off
						</Checkbox>
					)}
					{chart?.state?.flightModeOnOffSettings?.showFlightModeOnOffCheckBox && (
						<Checkbox
							id="flight-mode-on-off-check-box"
							data-test="showFlightModeOnOffValues"
							checked={chart.state.flightModeOnOffSettings.isEnabled}
							onClick={chart.onShowFlightModeOnOffClick}
						>
							Flight Mode On/Off
						</Checkbox>
					)}
					{chart?.state?.logStatusesSettings?.showLogStatusesCheckBox && (
						<Checkbox
							id="device-state-check-box"
							data-test="showLogStatusesValues"
							checked={chart.state.logStatusesSettings.isEnabled}
							onClick={chart.onShowLogStatusesClick}
						>
							Device State
						</Checkbox>
					)}
					{chart?.state?.sensorLimitAlarmsSettings?.showSensorLimitAlarmsCheckBox && (
						<Checkbox
							id="limit-alarms-check-box"
							data-test="showSensorLimitAlarmsValues"
							checked={chart.state.sensorLimitAlarmsSettings.isEnabled}
							onClick={chart.onShowSensorLimitAlarmsClick}
						>
							Limit Alarms
						</Checkbox>
					)}
					{chart?.state?.sensorIssueAlarmsSettings?.showSensorIssueAlarmsCheckBox && (
						<Checkbox
							id="issue-alarms-check-box"
							data-test="showSensorIssueAlarmsValues"
							checked={chart.state.sensorIssueAlarmsSettings.isEnabled}
							onClick={chart.onShowSensorIssueAlarmsClick}
						>
							Issue Alarms
						</Checkbox>
					)}
					{chart?.state?.menuSetMarkSettings?.showMenuSetMarkCheckBox && (
						<Checkbox
							id="mark-counter-check-box"
							data-test="showMenuSetMarkValues"
							checked={chart.state.menuSetMarkSettings.isEnabled}
							onClick={chart.onShowMenuSetMarkClick}
						>
							Mark Counter
						</Checkbox>
					)}
					{chart?.state?.sensorReplacesSettings?.showSensorReplacesCheckBox && (
						<Checkbox
							id="replace-sensor-check-box"
							data-test="showSensorReplacesValues"
							checked={chart.state.sensorReplacesSettings.isEnabled}
							onClick={chart.onShowReplaceSensorClick}
						>
							Replace Sensor
						</Checkbox>
					)}
				</AccordionItemBody>
			</AccordionItem>
		</Accordion>
	);
};

export default ChartLegend;
