import React, {useEffect, useState} from 'react';
import {Trans} from 'react-i18next';
import {Checkbox, Form} from 'antd';
import {showNoPhoneNumberWarning} from '../../Utils/RegistryHelper';
import IssueProfileSelectComponent from './IssueProfileSelectComponent';
import MissingCommunicationWarningSelectComponent from './MissingCommunicationWarningSelectComponent';
import {FormNavigation} from '../../../../Wizard/Elements';
import {CurrentPriceResult} from '../../../../../common/util/LicenseUtils/CurrentPriceExtension';
import {RecipientSelectState} from '../../../../../components/Wizard/FormItems/RecipientSelectState';
import {RecipientSelect} from '../../../../../components/Wizard/FormItems/RecipientSelect';
import SmsDowngradeWarningAlert from '../../Utils/SmsDowngradeWarningAlert';
import {DeviceUtils} from '../../../../../common/util';
import CurrentPrice from '../../../../../components/Common/CurrentPrice';
import {formProps} from '../../../../../components/Wizard/defaults';
import {IssueAlarmFormState, IssueProfile} from './IssueAlarmFormState';
import TiltWarningInitialPositionSelectComponent from './TiltWarningInitialPositionSelectComponent';
import TiltWarningThresholdAngleSelectComponent from './TiltWarningThresholdAngleSelectComponent';

type IssueAlarmFromComponentProps = {
	initialState: IssueAlarmFormState;
	moduleSerialNumber: string;
	currentPrice: CurrentPriceResult;
	onBack: () => void;
	onNext: () => void;
	onFormStateChange?: (limitAlarmFormState: any) => void;
	saveStateCallback: (limitAlarmFormState: any) => void;
};

const IssueAlarmFromComponent = ({
	initialState,
	moduleSerialNumber,
	onBack,
	onNext,
	currentPrice,
	onFormStateChange,
	saveStateCallback,
}: IssueAlarmFromComponentProps) => {
	const [form] = Form.useForm();

	const [useIssueProfileEnabled, setUseIssueProfileEnabled] = useState(!!initialState?.issueProfile);
	const [issueProfile, setIssueProfile] = useState(initialState?.issueProfile);
	const [formStateLoading, setFormStateLoading] = useState(false);
	const [issueProfilesLoading, setIssueProfilesLoading] = useState(true);
	const [recipientsLoading, setRecipientsLoading] = useState(true);
	const [recipientSelectState, setRecipientSelectState] = useState<RecipientSelectState>(null);

	const loading = issueProfilesLoading || recipientsLoading || formStateLoading;

	useEffect(() => {
		if (onFormStateChange) {
			onFormStateChange(getFormState());
		}
	}, [useIssueProfileEnabled, recipientSelectState, issueProfile]);

	const recipientsWithMissingPhoneNumbers = recipientSelectState?.sms.enabled
		? recipientSelectState?.sms.recipients.filter(u => !u.Mobile)
		: [];

	const getFormState = (): IssueAlarmFormState => ({
		issueProfile: issueProfile,
		recipients: recipientSelectState,
	});

	const handleBack = () => {
		setFormStateLoading(true);
		saveStateCallback(getFormState());
		onBack();
		setFormStateLoading(false);
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		setFormStateLoading(true);

		saveStateCallback(getFormState());

		if (recipientsWithMissingPhoneNumbers.length > 0) {
			showNoPhoneNumberWarning(() => {
				onNext();
			}, recipientsWithMissingPhoneNumbers);
		} else {
			onNext();
		}

		setFormStateLoading(false);
	};

	function onIssueProfileSelect(issueProfile: IssueProfile): void {
		setIssueProfile(issueProfile);
	}

	function toggleIssueProfileEnabled(enabled: boolean) {
		setUseIssueProfileEnabled(enabled);
		if (!enabled) {
			setIssueProfile(null);
		}
	}

	return (
		<Form form={form} name="formSensorIssues" onFinish={handleSubmit} layout={'vertical'} requiredMark={false} {...formProps}>
			<FormNavigation loading={loading} />
			<Form.Item>
				<Checkbox
					checked={useIssueProfileEnabled}
					name="use_issue_alarms"
					type="checkbox"
					onChange={event => toggleIssueProfileEnabled(event.target.checked)}
				>
					Use issue profiles
				</Checkbox>
			</Form.Item>
			<IssueProfileSelectComponent
				disabled={!useIssueProfileEnabled}
				initialIssueProfile={issueProfile}
				onSelectCallback={onIssueProfileSelect}
				onComponentReady={() => setIssueProfilesLoading(false)}
			/>
			<Form.Item>
				<Form.Item>
					<Checkbox
						checked={issueProfile?.missing_communication_warning}
						name="missing_communication_warning"
						type="checkbox"
						disabled
					>
						Missing communication warning
					</Checkbox>
				</Form.Item>
				<Form.Item>
					<MissingCommunicationWarningSelectComponent value={issueProfile?.missing_communication_warning_enum} disabled={true} />
				</Form.Item>
			</Form.Item>

			<Form.Item>
				<Checkbox checked={issueProfile?.sensor_failure_alarm} name="sensor_failure_alarm" type="checkbox" disabled>
					Sensor failure alarm
				</Checkbox>
			</Form.Item>
			<Form.Item>
				<Checkbox checked={issueProfile?.lost_measurement_alarm} name="lost_measurement_alarm" type="checkbox" disabled>
					<Trans i18nKey={'settings.issueProfiles.lostMeasurementAlarm'} />
				</Checkbox>
			</Form.Item>
			<Form.Item>
				<Checkbox checked={issueProfile?.low_battery_warning} name="low_battery_warning" type="checkbox" disabled>
					Low battery warning
				</Checkbox>
			</Form.Item>
			{DeviceUtils.IsLiberoGx(moduleSerialNumber) && (
				<Form.Item>
					<Form.Item>
						<Checkbox checked={issueProfile?.tilt_warning} name="tilt_warning" type="checkbox" disabled>
							Tilt warning
						</Checkbox>
					</Form.Item>
					<Form.Item>
						<TiltWarningInitialPositionSelectComponent value={issueProfile?.tilt_warning_initial_position} disabled={true} />
					</Form.Item>
					<Form.Item>
						<TiltWarningThresholdAngleSelectComponent value={issueProfile?.tilt_warning_angle} disabled={true} />
					</Form.Item>
				</Form.Item>
			)}
			<RecipientSelect
				form={form}
				initialState={initialState?.recipients}
				deviceFamily={DeviceUtils.GetDeviceFamily(moduleSerialNumber)}
				disabled={!useIssueProfileEnabled}
				onValueChanged={setRecipientSelectState}
				onComponentReady={() => setRecipientsLoading(false)}
			/>
			{currentPrice.isSmsDowngradeFromLicenseModel ? <SmsDowngradeWarningAlert /> : null}
			{!DeviceUtils.IsLiberoGx(moduleSerialNumber) ? <CurrentPrice currentPrice={currentPrice} /> : null}
			<FormNavigation loading={loading} onBack={handleBack} />
		</Form>
	);
};

export default IssueAlarmFromComponent;
