import CurrentPrice from '../../../../Common/CurrentPrice';
import React, {useContext, useEffect, useState} from 'react';
import {Checkbox, Col, Form, Row} from 'antd';
import {showNoPhoneNumberWarning} from '../../Utils/RegistryHelper';
import {FormNavigation} from '../../../../Wizard/Elements';
import {PredictiveTrans} from '../../../../Wizard';
import {useTranslation} from 'react-i18next';
import {FeatureFlag, FeatureFlagHelper} from '../../../../../common/helpers';
import {LimitAlarmFormState} from './LimitAlarmFormState';
import {formProps} from '../../../../Wizard/defaults';
import {AlarmLimitSelect} from '../../../../Wizard/FormItems';
import {AlarmLimit} from '../../../../../common/types';
import {DeviceUtils} from '../../../../../common/util';
import {RecipientSelect} from '../../../../Wizard/FormItems/RecipientSelect';
import {RecipientSelectState} from '../../../../Wizard/FormItems/RecipientSelectState';
import {ChannelType} from '../../AddSensor/WizardState';
import {SymbolValueString} from '../../Utils/DiscoverModule';
import SmsDowngradeWarningAlert from '../../Utils/SmsDowngradeWarningAlert';
import {AuthContext} from '../../../../../common/context';

type LimitAlarmFormProps = {
	channelType: ChannelType;
	currentPrice;
	onBack: () => void;
	onNext: () => void;
	initialState: LimitAlarmFormState;
	moduleSerialNumber: string;
	interval: number;
	onFormStateChange?: (limitAlarmFormState: LimitAlarmFormState) => void;
	unit: number;
	saveStateCallback: (limitAlarmFormState: LimitAlarmFormState) => void;
	sensorIndex: number;
};

const LimitAlarmsForm = ({
	currentPrice,
	onBack,
	onNext,
	channelType,
	initialState,
	moduleSerialNumber,
	interval,
	onFormStateChange,
	unit,
	saveStateCallback,
	sensorIndex,
}: LimitAlarmFormProps) => {
	const [t] = useTranslation();
	const [form] = Form.useForm();

	const [useLimitProfileEnabled, setUseLimitProfileEnabled] = useState(!!initialState?.alarmLimit);
	const [alarmLimit, setAlarmLimit] = useState<AlarmLimit>(initialState?.alarmLimit);
	const [predictiveEnabled, setPredictiveEnabled] = useState(initialState?.predictive?.enabled ?? false);
	const [featureFlags, setFeatureFlags] = useState(new Map<string, boolean>());
	const [recipientSelectState, setRecipientSelectState] = useState<RecipientSelectState>(null);

	const [limitProfilesLoading, setLimitProfilesLoading] = useState(true);
	const [recipientsLoading, setRecipientsLoading] = useState(true);
	const [formStateLoading, setFormStateLoading] = useState(false);
	const loading = limitProfilesLoading || recipientsLoading || formStateLoading;

	const authContext = useContext(AuthContext);

	const predictiveAvailable =
		authContext.Organization.Config.global.predictiveEnabled &&
		[SymbolValueString.temperature_int, SymbolValueString.temperature].includes(channelType.symbolValue) &&
		sensorIndex == 0;

	const notificationsEnabled: boolean = useLimitProfileEnabled || predictiveEnabled;

	useEffect(() => {
		FeatureFlagHelper.GetFeatureFlagsMap([FeatureFlag.Licensing])
			.then(r => {
				setFeatureFlags(r);
			})
			.catch();
	}, []);

	useEffect(() => {
		if (onFormStateChange) {
			onFormStateChange(getFormState());
		}
	}, [useLimitProfileEnabled, alarmLimit, predictiveEnabled, recipientSelectState]);

	const recipientsWithMissingPhoneNumbers = recipientSelectState?.sms.enabled
		? recipientSelectState?.sms.recipients.filter(u => !u.Mobile)
		: [];

	const getFormState = (): LimitAlarmFormState => ({
		predictive: {enabled: predictiveEnabled},
		alarmLimit: alarmLimit,
		recipients: recipientSelectState,
		unit: unit,
	});

	const onSubmit = _ => {
		setFormStateLoading(true);

		saveStateCallback(getFormState());

		if (recipientsWithMissingPhoneNumbers.length > 0) {
			showNoPhoneNumberWarning(() => {
				onNext();
			}, recipientsWithMissingPhoneNumbers);
		} else {
			onNext();
		}

		setFormStateLoading(false);
	};

	const onNavigateBack = () => {
		setFormStateLoading(true);
		saveStateCallback(getFormState());
		onBack();
		setFormStateLoading(false);
	};

	function onAlarmLimitSelect(alarmLimit: AlarmLimit) {
		setAlarmLimit(alarmLimit);
	}

	function toggleLimitProfileEnabled(enabled: boolean) {
		setUseLimitProfileEnabled(enabled);
		if (!enabled) {
			setAlarmLimit(null);
		}
	}

	return (
		<Form form={form} name="formLimitAlarms" onFinish={onSubmit} layout={'vertical'} requiredMark={false} {...formProps}>
			<Row>
				{sensorIndex !== null && (
					<Col>
						<h4>
							Sensor {sensorIndex + 1}: {channelType.symbolValue}
						</h4>
					</Col>
				)}
				<Col flex={1} />

				<Col>
					<FormNavigation loading={loading} />
				</Col>
			</Row>

			<Form.Item>
				<Form.Item style={{display: 'inline-block'}}>
					<Checkbox
						checked={useLimitProfileEnabled}
						name="use_limit_alarms"
						type="checkbox"
						onChange={event => toggleLimitProfileEnabled(event.target.checked)}
					>
						Use limit profiles
					</Checkbox>
				</Form.Item>

				{DeviceUtils.IsLiberoGx(moduleSerialNumber) && predictiveAvailable && (
					<Form.Item
						style={{display: 'inline-block'}}
						label=""
						name="PredictiveEnabled"
						tooltip={'Will be configured on a separate page, configure the notifications here'}
					>
						<Checkbox checked={predictiveEnabled} onChange={e => setPredictiveEnabled(e.target.checked)}>
							{t('global.enableX', {x: t(PredictiveTrans.title)}).toString()}
						</Checkbox>
					</Form.Item>
				)}
			</Form.Item>

			<AlarmLimitSelect
				disabled={!useLimitProfileEnabled}
				initialAlarmLimit={alarmLimit}
				loggingInterval={interval}
				onSelectCallback={onAlarmLimitSelect}
				outUnit={unit}
				onComponentReady={() => setLimitProfilesLoading(false)}
			/>

			<RecipientSelect
				form={form}
				initialState={initialState?.recipients}
				deviceFamily={DeviceUtils.GetDeviceFamily(moduleSerialNumber)}
				disabled={!notificationsEnabled}
				onValueChanged={setRecipientSelectState}
				onComponentReady={() => setRecipientsLoading(false)}
			/>

			{currentPrice.isSmsDowngradeFromLicenseModel ? <SmsDowngradeWarningAlert /> : null}

			{featureFlags?.get(FeatureFlag.Licensing) || !DeviceUtils.IsLiberoGx(moduleSerialNumber) ? (
				<CurrentPrice currentPrice={currentPrice} />
			) : null}
			<FormNavigation loading={loading} onBack={onNavigateBack} />
		</Form>
	);
};

export default LimitAlarmsForm;
