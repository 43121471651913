import {COLUMNS} from '../Constants/Columns';
import {CONSTANTS} from '../Constants/Constants';

export const prepareSingleRowData = (callbackMethodsForHamburgerMenu, issueProfile, parent, i) => {
	if (!callbackMethodsForHamburgerMenu || !issueProfile || !parent || !i) {
		throw new TypeError('Please pass required parameters!!!');
	}

	const menu = {callbackMethodsForHamburgerMenu: callbackMethodsForHamburgerMenu, issueProfile: issueProfile, parent};
	const id = issueProfile.id;
	const issueProfileName = prepareIssueProfileName(issueProfile);
	const sensorFailureAlarm = prepareSensorFailureAlarm(issueProfile);
	const lowBatteryWarning = prepareLowBatteryWarning(issueProfile);
	const lostMeasurement = prepareLostMeasurement(issueProfile);
	const missingCommunication = prepareMissingCommunication(issueProfile);
	const lightDetector = prepareLightDetector(issueProfile);
	const tiltDetector = prepareTiltDetector(issueProfile);
	const inUsedBy = prepareInUsedBy(issueProfile);
	const locked = prepareLocked(issueProfile);

	let obj = {};
	Object.defineProperty(obj, COLUMNS.MENU, {
		value: menu,
		writable: true,
	});
	Object.defineProperty(obj, 'id', {
		value: id,
		writable: true,
	});
	Object.defineProperty(obj, COLUMNS.ISSUE_PROFILE_NAME, {
		value: issueProfileName,
		writable: true,
	});
	Object.defineProperty(obj, COLUMNS.SENSOR_FAILURE_ALARM, {
		value: sensorFailureAlarm,
		writable: true,
	});
	Object.defineProperty(obj, COLUMNS.LOW_BATTERY_WARNING, {
		value: lowBatteryWarning,
		writable: true,
	});
	Object.defineProperty(obj, COLUMNS.LOST_MEASUREMENT_ALARM, {
		value: lostMeasurement,
		writable: true,
	});
	Object.defineProperty(obj, COLUMNS.MISSING_COMMUNICATION_WARNING, {
		value: missingCommunication,
		writable: true,
	});
	Object.defineProperty(obj, COLUMNS.LIGHT_WARNING, {
		value: lightDetector,
		writable: true,
	});
	Object.defineProperty(obj, COLUMNS.TILT_WARNING, {
		value: tiltDetector,
		writable: true,
	});
	Object.defineProperty(obj, COLUMNS.IN_USE_BY, {
		value: inUsedBy,
		writable: true,
	});
	Object.defineProperty(obj, COLUMNS.LOCKED, {
		value: locked,
		writable: true,
	});
	return obj;
};

function prepareIssueProfileName(issueProfile) {
	if (!issueProfile) {
		throw new TypeError('Please pass issueProfile object!!!');
	}
	return issueProfile.name ? issueProfile.name : CONSTANTS.DEFAULT;
}

function prepareSensorFailureAlarm(issueProfile) {
	if (!issueProfile) {
		throw new TypeError('Please pass issueProfile object!!!');
	}
	return issueProfile.sensor_failure_alarm ?? CONSTANTS.DEFAULT;
}

function prepareLowBatteryWarning(issueProfile) {
	if (!issueProfile) {
		throw new TypeError('Please pass issueProfile object!!!');
	}
	return issueProfile.low_battery_warning ?? CONSTANTS.DEFAULT;
}

function prepareLostMeasurement(issueProfile) {
	if (!issueProfile) {
		throw new TypeError('Please pass issueProfile object!!!');
	}
	return issueProfile.lost_measurement_alarm ?? CONSTANTS.DEFAULT;
}

function prepareMissingCommunication(issueProfile) {
	if (!issueProfile) {
		throw new TypeError('Please pass issueProfile object!!!');
	}
	return issueProfile.missing_communication_warning ?? CONSTANTS.DEFAULT;
}

function prepareLightDetector(issueProfile) {
	if (!issueProfile) {
		throw new TypeError('Please pass issueProfile object!!!');
	}
	return issueProfile.light_warning ?? CONSTANTS.DEFAULT;
}

function prepareTiltDetector(issueProfile) {
	if (!issueProfile) {
		throw new TypeError('Please pass issueProfile object!!!');
	}
	return issueProfile.tilt_warning ?? CONSTANTS.DEFAULT;
}

function prepareInUsedBy(issueProfile) {
	if (!issueProfile) {
		throw new TypeError('Please pass issueProfile object!!!');
	}
	return issueProfile.sensors && issueProfile.sensors.length > 0 ? issueProfile.sensors.length : CONSTANTS.DEFAULT;
}

function prepareLocked(issueProfile) {
	if (!issueProfile) {
		throw new TypeError('Please pass issueProfile object!!!');
	}
	return issueProfile.locked ?? CONSTANTS.DEFAULT;
}

if (process.env['BABEL_ENV'] === 'test' || process.env['NODE_ENV'] === 'test') {
	//Functions are only visible for testing purpose.
	module.exports.prepareIssueProfileName = prepareIssueProfileName;
	module.exports.prepareSensorFailureAlarm = prepareSensorFailureAlarm;
	module.exports.prepareLowBatteryWarning = prepareLowBatteryWarning;
	module.exports.prepareLostMeasurement = prepareLostMeasurement;
	module.exports.prepareMissingCommunication = prepareMissingCommunication;
	module.exports.prepareLightDetector = prepareLightDetector;
	module.exports.prepareTiltDetector = prepareTiltDetector;
	module.exports.prepareInUsedBy = prepareInUsedBy;
	module.exports.prepareLocked = prepareLocked;
}
